<template>
  <div class="live">
    <div id="local_stream"></div>
    <div id="remote_stream" v-if="remoteShow"></div>
    <div class="menu-float">
      <div class="header">
        <div class="left" @click="leaveRoom">
          <img src="../../../../assets/img/back.png"/>
        </div>
        <div class="middle">{{ roomInfo.live_title }}</div>
        <div class="right">
          <div class="users-num">
            {{ count }} 人
          </div>
        </div>
      </div>
      <van-notice-bar left-icon="volume-o" scrollable :text="roomInfo.live_notice"/>
      <div class="footer">
        <div class="main-icon">
          <div class="item" @click="trunMic">
            <img v-if="callStatus.mic" src="../../../../assets/img/mic-on.png"/>
            <img v-else src="../../../../assets/img/mic-off.png"/>
          </div>
          <div class="item" @click="leaveRoom">
            <img src="../../../../assets/img/hand-off.png"/>
          </div>
          <div class="item" @click="trunCamera">
            <img v-if="callStatus.camera" src="../../../../assets/img/camera-on.png"/>
            <img v-else src="../../../../assets/img/camera-off.png"/>
          </div>
        </div>
        <div class="tools">
          <div class="toggle-menu" v-if="callStatus.setting">
            <div class="item" @click="toggleCamera">
              <img src="../../../../assets/img/toggle-camera.png"/>
            </div>
            <div class="item" @click="toggleMic">
              <img src="../../../../assets/img/toggle-mic.png"/>
            </div>
          </div>
          <div class="toggle" @click="toggleSetting">
            <img v-if="!callStatus.setting" src="../../../../assets/img/setting-off.png"/>
            <img v-else src="../../../../assets/img/setting.png"/>
          </div>
        </div>
        <div class="room-introduce">
          <div class="toggle-icon" @click="toggleIntroduce">
            <img src="../../../../assets/img/info.png"/>
          </div>
        </div>
        <div class="message-panel">
          <div class="msg-list">
            <div class="item" v-for="(item,index) in messageList" :key="index" :index="index">
              <div class="system-msg" v-if="item.type === 'GRP_TIPS'">
                <span class="name">系统消息：</span>
                <span class="msg">{{ item.msg}}</span>
              </div>
              <div class="user-msg" v-else>
                <span class="name">{{ item.from_nick ? item.from_nick : item.from_user }} ：</span>
                <span class="msg">{{ item.msg }}</span>
              </div>
            </div>
          </div>
          <div class="send-panel">
            <van-field v-model="sendMsg" placeholder="">
              <template #button>
                <van-button size="small" type="primary" @click="sendGroupMsg">发送</van-button>
              </template>
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择摄像头弹窗-->
    <van-action-sheet description="摄像头列表" v-model="pickerCameraShow" :name="deviceList.cameraList.label" :actions="deviceList.cameraList" close-on-click-action cancel-text="取消" @select="onCameraSelect" />
    <!-- 选择麦克风弹窗-->
    <van-action-sheet description="麦克风列表" v-model="pickerMicShow" :name="deviceList.micList.label" :actions="deviceList.micList" close-on-click-action cancel-text="取消" @select="onMicSelect" />
    <!--房间简介弹窗-->
    <van-popup class="introduce-model" v-model="introduceShow" round position="bottom" :style="{ height: 'auto' }" >
      {{ roomInfo.live_introduce }}
    </van-popup>
    <!--显示房间分辨率-->
    <div class="room-definition">
      <div class="text">
        <span>当前分辨率: </span>
        <span v-if="roomInfo.definition === 0">480P</span>
        <span v-else-if="roomInfo.definition === 1">720P</span>
        <span v-else-if="roomInfo.definition === 2">1080P</span>
        <span v-else-if="roomInfo.definition === 3">1440P</span>
        <span v-else-if="roomInfo.definition === 4">4k高清</span>
        <span v-else>获取失败</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Live',
  data () {
    return {
      count: 0,
      sendMsg: '',
      messageList: [],
      client: {},
      localStream: {},
      remoteStream: {},
      remoteShow: false,
      callStatus: {
        mic: true, // mic图标状态
        camera: true, // camera图标状态
        setting: false // 设置图标状态
      },
      deviceList: {
        micList: [],
        activeMic: '',
        cameraList: [],
        activeCamera: ''
      },
      pickerCameraShow: false, // 选择摄像头弹窗
      pickerMicShow: false, // 选择麦克风弹窗
      roomInfo: {
        live_title: '',
        live_introduce: '',
        live_notice: '',
        live_avatar: '',
        live_anchor: '',
        definition: 0,
        unique: ''
      },
      introduceShow: false // 房间介绍弹窗
    }
  },
  created () {
    this.getRoomInfo()
    this.createClient()
    this.joinRoom()
  },
  methods: {
    // 消息监听
    websocketonmessage (e) { // 数据接收
      console.log(e)
      const redata = JSON.parse(e.data)
      console.log(redata)
      //  群提示消息 || 群发言消息
      if (redata.type === 'GRP_TIPS' || redata.type === 'GRP_MSG' || redata.type === 'INVITE_MSG') {
        this.messageList.push(redata)
        this.count = redata.count
      }
    },
    toggleIntroduce () {
      this.introduceShow = true
    },
    // ws加入房间
    async wsJoinRoom () {
      // 如果vuex中有client_id则请求加入群组，如果不满足则通过 websocketonmessage 的 init 重新绑定到uid再加入群组
      if (this.$store.state.wsInfo.client_id) {
        await this.$http.post('/index/ws-join-group', { client_id: this.$store.state.wsInfo.client_id, group_id: this.$route.params.unique })
      }
    },
    // WS发送群组消息
    async sendGroupMsg () {
      await this.$http.post('/index/send-group-msg', { group_id: this.$route.params.unique, msg: this.sendMsg })
      this.sendMsg = ''
    },
    // WS退出房间
    async wsLeaveGroup () {
      await this.$http.post('/index/ws-leave-group', { client_id: this.$store.state.wsInfo.client_id, group_id: this.$route.params.unique })
    },
    // 获取直播间数据
    async getRoomInfo () {
      const { data: res } = await this.$http.get('/index/live-room-info/' + this.$store.state.userInfo.username)
      if (res.meta.status === 200) {
        this.roomInfo.live_title = res.data.live_title
        this.roomInfo.live_introduce = res.data.live_introduce
        this.roomInfo.live_notice = res.data.live_notice
        this.roomInfo.live_avatar = res.data.live_avatar
        this.roomInfo.live_anchor = res.data.live_anchor
        this.roomInfo.definition = res.data.definition
        this.roomInfo.unique = res.data.unique
        // 开启消息监听
        this.$store.state.wsInfo.client.onmessage = this.websocketonmessage
        // ws进房
        this.wsJoinRoom()
      } else {
        this.$toast.fail(res.meta.msg)
        this.$router.push('/live-room/' + this.$store.state.userInfo.username)
      }
    },
    // 切换摄像头
    toggleCamera () {
      this.$TRTC.getCameras().then(devices => {
        this.deviceList.cameraList = []
        devices.forEach(dev => {
          this.deviceList.cameraList.push({ name: dev.label, subname: dev.deviceId })
        })
        this.pickerCameraShow = true
      })
    },
    onCameraSelect (item) {
      this.localStream.switchDevice('video', item.subname).then(() => {
        this.$toast.success('切换摄像头：' + item.name + ' 成功！')
      })
    },
    // 切换麦克风
    toggleMic () {
      this.$TRTC.getMicrophones().then(devices => {
        this.deviceList.micList = []
        devices.forEach(dev => {
          this.deviceList.micList.push({ name: dev.label, subname: dev.deviceId })
        })
        this.pickerMicShow = true
      })
    },
    onMicSelect (item) {
      this.localStream.switchDevice('audio', item.subname).then(() => {
        this.$toast.success('切换麦克风：' + item.name + ' 成功！')
      })
    },
    // 退出房间
    leaveRoom () {
      const that = this
      // 取消发布本地流
      this.client.unpublish(this.localStream).then(() => {
        // 取消发布本地流成功
        that.client.leave().then(() => {
          // leaving room success
          that.$toast.success('退出房间成功！，请稍等')
          that.$router.push('/user/live-enter')
        }).catch(error => {
          that.$toast.fail('退出房间失败！' + error)
        })
      })
    },
    // 开启关闭麦克风
    trunMic () {
      if (this.callStatus.mic) {
        // 关闭音频，远端会收到 ‘mute-audio’ 事件
        this.localStream.muteAudio()
        this.$toast.success('已关闭麦克风')
        this.callStatus.mic = false
      } else {
        // 启用音频，远端会收到 ‘unmute-audio’ 事件
        this.localStream.unmuteAudio()
        this.$toast.success('已开启麦克风')
        this.callStatus.mic = true
      }
    },
    // 开启关闭摄像头
    trunCamera () {
      if (this.callStatus.camera) {
        // 关闭视频，远端会收到 ‘mute-video’ 事件
        this.localStream.muteVideo()
        this.$toast.success('已关闭摄像头')
        this.callStatus.camera = false
      } else {
        // 启用视频，远端会收到 ‘unmute-video’ 事件
        this.localStream.unmuteVideo()
        this.$toast.success('已开启摄像头')
        this.callStatus.camera = true
      }
    },
    toggleSetting () {
      this.callStatus.setting = !this.callStatus.setting
    },
    // 创建 Client 对象
    createClient () {
      this.client = this.$TRTC.createClient({
        mode: 'live',
        sdkAppId: this.$store.state.userInfo.appid,
        userId: this.$store.state.userInfo.username,
        userSig: this.$store.state.userInfo.token,
        useStringRoomId: true
      })
      this.$TRTC.Logger.setLogLevel(this.$TRTC.Logger.LogLevel.ERROR)
    },
    //  进入房间
    joinRoom () {
      this.client.join({ roomId: this.$route.params.unique, role: 'anchor' })
        .catch(error => {
          console.error('进房失败 ' + error)
        })
        .then(() => {
          console.log('进房成功')
          this.createStream()
        })
    },
    // 创建本地音视频流 并初始化
    createStream () {
      this.localStream = this.$TRTC.createStream({ userId: this.$store.state.userInfo.username, audio: true, video: true })
      if (this.roomInfo.definition === 0) {
        this.localStream.setVideoProfile({ width: 640, height: 480, frameRate: 15, bitrate: 900 })
      } else if (this.roomInfo.definition === 1) {
        this.localStream.setVideoProfile({ width: 1280, height: 720, frameRate: 15, bitrate: 1500 })
      } else if (this.roomInfo.definition === 2) {
        this.localStream.setVideoProfile({ width: 1920, height: 1080, frameRate: 30, bitrate: 2000 })
      } else if (this.roomInfo.definition === 3) {
        this.localStream.setVideoProfile({ width: 2560, height: 1440, frameRate: 30, bitrate: 4860 })
      } else if (this.roomInfo.definition === 4) {
        this.localStream.setVideoProfile({ width: 3840, height: 2160, frameRate: 30, bitrate: 9000 })
      }
      this.localStream
        .initialize()
        .catch(error => {
          console.error('初始化本地流失败 ' + error)
        })
        .then(() => {
          console.log('初始化本地流成功')
          // 发布本地流
          this.publish()
        })
    },
    // 发布本地音视频流
    publish () {
      this.client
        .publish(this.localStream)
        .catch(error => {
          console.error('本地流发布失败 ' + error)
        })
        .then(() => {
          console.log('本地流发布成功')
          this.localStream.objectFit = 'contain'
          this.localStream.play('local_stream', { objectFit: 'contain' })
          // 获取实际采集的帧率/分辨率信息
          const videoTrack = this.localStream.getVideoTrack()
          if (videoTrack) {
            const settings = videoTrack.getSettings()
            console.log(`当前摄像头信息：分辨率：${settings.width} * ${settings.height}, 帧率：${settings.frameRate}`)
          } else {
            console.log(videoTrack)
          }
          this.subscribe()
        })
    },
    // 订阅远端音视频流
    subscribe () {
      this.client.on('stream-added', event => {
        const remoteStream = event.stream
        this.remoteStream = remoteStream
        console.log('远端流增加: ' + remoteStream.getId())
        // 订阅远端流
        this.client.subscribe(remoteStream)
      })
      this.client.on('stream-subscribed', event => {
        const remoteStream = event.stream
        this.remoteStream = remoteStream
        this.remoteShow = true
        console.log('远端流订阅成功：' + remoteStream.getId())
        // 播放远端流
        remoteStream.play('remote_stream', { objectFit: 'contain' })
      })
    }
  },
  computed: {
    wsCliten () {
      return this.$store.state.wsInfo.client
    }
  },
  updated () {
    this.$nextTick(() => {
      var container = this.$el.querySelector('.msg-list')
      container.scrollTop = container.scrollHeight
    })
  }
}
</script>

<style scoped>
.room-definition{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #ed6a0c;
  text-align: center;
}
.room-definition .text{
  background-color: #fffbe8;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
}
.live{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
}
#local_stream{
  width: 100%;
  height: 100vh;
}
.menu-float{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.menu-float .header{
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-float .header .left{}
.menu-float .header .left img{
  width: 40px;
  height: 40px;
}
.menu-float .header .middle{
  font-size: 16px;
  font-weight: bold;
}
.menu-float .header .right{}
.menu-float .header .right .users-num{
  font-size: 14px;
  background-color: rgba(255,255,255,0.8);
  padding: 4px 10px;
  border-radius: 30px;
}
.menu-float .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0px 20px 40px 20px;
}
.menu-float .footer .main-icon{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
}
.menu-float .footer .main-icon .item{}
.menu-float .footer .main-icon .item img{
  width: 40px;
}
.menu-float .footer .main-icon .item:nth-child(2) img{
  width: 50px;
}
.menu-float .footer .tools{
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: 100;
}
.menu-float .footer .tools .toggle{}
.menu-float .footer .tools .toggle img{
  width: 30px;
}
.menu-float .footer .tools .toggle-menu{
  margin-bottom: 10px;
}
.menu-float .footer .tools .toggle-menu .item{
  margin: 5px 0;
}
.menu-float .footer .tools .toggle-menu .item img{
  width: 30px;
}
.room-introduce{
  position: absolute;
  bottom: 100px;
  left: 20px;
}
.room-introduce .toggle-icon{}
.room-introduce .toggle-icon img{
  width: 30px;
}
.introduce-model{
  padding: 60px 30px;
  font-size: 14px;
}
.message-panel{
  position: absolute;
  bottom: 150px;
  left: 10%;
  width: 80%;
  box-sizing: border-box;
}
.message-panel .msg-list{
  max-height: 150px;
  overflow-y: scroll;
}
.message-panel .msg-list .item{
  font-size: 14px;
}
.message-panel .msg-list .item .system-msg{
  color: #facc31;
}
.message-panel .msg-list .item .user-msg{}
.message-panel .msg-list .item .user-msg .name{
  color: #07c160;
}
.message-panel .msg-list .item .msg{
  max-width: 100%;
  word-wrap: break-word;
  word-break: normal;
}
.message-panel .send-panel{
  margin-top: 6px;
}
.message-panel .van-cell {
  padding: 4px 6px;
  border-radius: 4px;
  background-color: rgba(255,255,255,0.8);
}
</style>
