<template>
  <div class="message-main">
    <van-nav-bar :fixed="true" :placeholder="true" :title="userDetails.nick ? userDetails.nick : userDetails.username" left-text="返回"  left-arrow @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="ellipsis" size="18"/>
      </template>
    </van-nav-bar>
    <div class="chat-content" @click="chatContent">
      <div class="system-message">
        <div class="content">
          系统提示：请勿轻易相信来路不明好友， 谨防诈骗。
        </div>
      </div>
      <div class="message-item" v-for="(item,index) in addVuexMessgea" :key="index" :index="index">
        <div class="other-side" v-if="item.from_id === userDetails.id">
          <div class="user-avatar">
            <div class="img" :style="{ 'backgroundImage':'url('+ userDetails.avatar +')' }"/>
          </div>
          <div class="message">
            {{ item.msg }}
          </div>
          <div class="time">
            {{ dateFormat(item.time) }}
          </div>
        </div>
        <div class="my-side" v-else>
          <div class="user-avatar">
            <div class="img" :style="{ 'backgroundImage':'url('+ userDetails.myavatr +')' }"></div>
          </div>
          <div class="message">
            {{ item.msg }}
          </div>
          <div class="time">
            {{ item.time }}
          </div>
        </div>
      </div>
    </div>
    <div class="send-message-bar">
      <div class="input-area">
        <van-field
          id="input-text"
          v-model="message"
          rows="1"
          autosize
          type="textarea"
          placeholder="请输入内容"
        />
        <div class="tools">
          <van-icon name="smile-o" size="24" @click="emojiShow = !emojiShow"/>
          <van-icon name="add-o" size="24" v-if="!message"/>
          <van-button type="primary" v-else @click="sendMessage">发送</van-button>
        </div>
      </div>
      <!--表情弹出层-->
      <div class="emotid-show" v-if="emojiShow">
        <VEmojiV2 :showSearch="false" @select="SelectEmoji"></VEmojiV2>
      </div>
    </div>
    <!--好友详细资料弹出层-->
    <van-popup class="user-profile" v-model="profileShow" position="right" :style="{ width: '70%', height: '100%' }">
      <div class="user-info">
        <div class="left">
          <div class="avatar" :style="{ 'backgroundImage':'url('+ userDetails.avatar +')' }"></div>
        </div>
        <div class="right">
          <div class="nickname">{{ userDetails.nick }}</div>
          <div class="account">用户账号：{{ userDetails.username }}</div>
          <div class="area">{{ userDetails.location }}</div>
          <div class="selfSignature">
            {{ userDetails.selfSignature }}
          </div>
        </div>
      </div>
      <div class="delete-friend">删除好友</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'MessageMain',
  data () {
    return {
      emojiShow: false,
      profileShow: false,
      message: '',
      userDetails: {},
      messageList: []
    }
  },
  created () {
    // 获取聊天用户资料
    this.getChatUserDetails()
  },
  methods: {
    // 消息监听
    async getChatUserDetails () {
      const { data: res } = await this.$http.get('/index/get-chat-details/' + this.$route.params.id)
      if (res.meta.status === 200) {
        this.userDetails = res.data
        // // 开启消息监听
        // this.$store.state.wsInfo.client.onmessage = this.websocketonmessage
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    onClickRight () {
      this.profileShow = true
    },
    SelectEmoji (emoji) {
      const elInput = document.getElementById('input-text') // 根据id选择器选中对象
      const startPos = elInput.selectionStart // input 第0个字符到选中的字符
      const endPos = elInput.selectionEnd // 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return
      const txt = elInput.value
      // 将表情添加到选中的光标位置
      const result = txt.substring(0, startPos) + emoji.data + txt.substring(endPos)
      elInput.value = result// 赋值给input的value
      // 重新定义光标位置
      elInput.focus()
      elInput.selectionStart = startPos + emoji.data.length
      elInput.selectionEnd = startPos + emoji.data.length
      this.message = result// 赋值给表单中的的字段
    },
    chatContent () {
      this.emojiShow = false
    },
    // 发送消息
    async sendMessage () {
      if (this.message) {
        await this.$http.post('/index/send-to-friend', { to: this.userDetails.id, msg: this.message })
        this.messageList.push({ identity: 'my', msg: this.message, time: this.$moment(new Date(), 'YYYY-MM-DD HH:mm:ss').add(1, 's').format('YYYY-MM-DD HH:mm:ss') })
        this.message = ''
        this.emojiShow = false
      } else {
        this.$toast.fail('请输入内容')
      }
    },
    // 格式化时间
    dateFormat (time) {
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    wsCliten () {
      return this.$store.state.wsInfo.client
    },
    addVuexMessgea () {
      const that = this
      const vuexMessage = this.$store.state.messageList
      // 查找该用户发送的消息数据
      const vuexMessageList = vuexMessage.filter(function (item, index, arr) {
        if (item.from_id === that.userDetails.id) {
          that.$store.commit('messageReded', index)
          return item
        }
      })
      if (vuexMessageList.length > 0) {
        vuexMessageList[0].history.forEach((item, index, arr) => { // item为arr的元素，index为下标，arr原数组
          // 更改vuex中消息状态为true
          that.messageList.push(item)
        })
      }
      return that.messageList
    }
  },
  updated () {
    this.$nextTick(() => {
      var container = this.$el.querySelector('.chat-content')
      container.scrollTop = container.scrollHeight
    })
  }
}
</script>

<style scoped>
.message-main{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.chat-content{
  padding: 10px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
}
.chat-content .system-message{
  width: 100%;
  padding: 0px 20px 10px 20px;
}
.chat-content .system-message .content{
  width: 100%;
  font-size: 14px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 6px;
  text-align: left;
}
.message-item{
  margin-top: 40px;
}
.chat-content .other-side{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin: 10px;
}
.chat-content .other-side .user-avatar{
  width: 40px;
  height: 40px;
}
.chat-content .other-side .user-avatar .img{
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
}
.chat-content .other-side .message{
  flex: 1;
  padding: 4px 10px;
  font-size: 14px;
  background-color: rgba(255,255,255,1);
  border-radius: 6px;
  box-sizing: border-box;
  margin-left: 14px;
  position: relative;
  min-height: 44px;
  word-wrap: break-word;
  max-width: calc(100% - 60px);
}
.chat-content .other-side .time {
  position: absolute;
  top: -20px;
  font-size: 12px;
  opacity: 0.6;
  direction: initial;
  width: 100%;
  text-align: center;
}
.chat-content .other-side .message::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  /* 箭头靠右边 */
  top: 13px;
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgba(255,255,255,1);
}
.chat-content .my-side{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  direction: rtl;
  margin: 10px;
}
.chat-content .my-side .user-avatar{
  width: 40px;
  height: 40px;
}
.chat-content .my-side .user-avatar .img{
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
}
.chat-content .my-side .message{
  flex: 1;
  padding: 4px 10px;
  font-size: 14px;
  background-color: rgba(7, 193, 96,1);
  border-radius: 6px;
  box-sizing: border-box;
  margin-right: 14px;
  position: relative;
  color: #FFFFFF;
  text-align: left;
  direction: ltr;
  min-height: 44px;
  word-wrap: break-word;
  max-width: calc(100% - 60px);
}
.chat-content .my-side .time {
  position: absolute;
  top: -20px;
  font-size: 12px;
  opacity: 0.6;
  direction: initial;
  width: 100%;
  text-align: center;
}
.chat-content .my-side .message::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  /* 箭头靠右边 */
  top: 13px;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(7, 193, 96,1);
}
.send-message-bar{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.send-message-bar .input-area{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 10px;
}
.send-message-bar .van-field{
  width: 74%;
}
.send-message-bar .tools{
  width: 26%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.send-message-bar .tools .van-icon{
}
.user-profile{}
.user-profile .user-info{
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.user-profile .user-info .left{
  width: 40px;
}
.user-profile .user-info .left .avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
}
.user-profile .user-info .right{
  flex: 1;
  margin-left: 10px;
}
.user-profile .user-info .right .nickname{
  font-weight: bold;
  font-size: 15px;
}
.user-profile .user-info .right .account{
  font-size: 14px;
  opacity: 0.7;
  margin-top: 2px;
}
.user-profile .user-info .right .area{
  font-size: 14px;
  opacity: 0.7;
  margin-top: 2px;
}
.user-profile .user-info .right .selfSignature{
  font-size: 14px;
  opacity: 0.7;
  border-top: 1px dashed rgba(0,0,0,0.1);
  margin-top: 6px;
  padding-top: 6px;
}
.user-profile .delete-friend{
  background-color: #FFBB12;
  color: #FFFFFF;
  padding: 10px;
  font-size: 13px;
  margin: 10%;
  border-radius: 4px;
  text-align: center;
}
.emotid-show{
}
/deep/.container-emoji{
  height: 140px;
}
</style>
