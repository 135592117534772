<template>
  <div class="home-main">
    <search-bar></search-bar>
    <banner-bar></banner-bar>
    <room-list></room-list>
  </div>
</template>

<script>
import SearchBar from './widgets/SearchBar'
import Banner from './widgets/Banner'
import RoomList from './widgets/RoomList'
export default {
  name: 'HomeMain',
  components: {
    'search-bar': SearchBar,
    'banner-bar': Banner,
    'room-list': RoomList
  }
}
</script>

<style scoped>

</style>
