<template>
  <div class="live-room">
    <van-nav-bar :fixed="true" :placeholder="true" title="直播间" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <!-- 未申请-->
    <div class="no-apply" v-if="applyStatus === 1">
      <div class="tips">
        您还未申请直播
      </div>
      <div class="apply-btn" @click="applyShow = true">立即申请</div>
    </div>
    <!-- 等待审核中 -->
    <div class="examine" v-if="applyStatus === 2">
      <div class="tips">
        您的申请已提交，请等待管理员审核
      </div>
    </div>
    <!-- 申请已通过 -->
    <div class="has-apply" v-if="applyStatus === 3">
      <div class="live-setting" @click="settingShow = true">
        直播间资料设置
      </div>
      <div class="live-now" @click="toLive" v-if="!isOverTime">
        <span>现在开播</span>
      </div>
      <div class="live-now" v-else>
        <span>房间已到期，请联系管理员更续时长</span>
      </div>
    </div>
    <!-- 申请已被拒绝 -->
    <div class="refuse" v-if="applyStatus === 4">
      <div class="tips">
        您的申请已被拒绝，你可以重新填写申请资料
      </div>
      <div class="apply-btn" @click="applyShow = true">立即申请</div>
    </div>
    <div class="wave">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
        </g>
      </svg>
    </div>
    <!-- 设置直播间资料 -->
    <van-popup v-model="settingShow" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="editSubmit" class="setting-from">
        <van-field v-model="settingFrom.name" name="name" label="直播间名称" placeholder="直播间名称" :rules="[{ required: true, message: '请填写直播间名称' }]"/>
        <van-field v-model="settingFrom.introduction" name="introduction" rows="2" autosize label="直播间简介" type="textarea" maxlength="50" placeholder="请输入直播间简介" show-word-limit :rules="[{ required: true, message: '请填写直播间简介' }]"/>
        <van-field v-model="settingFrom.notification" name="notification" rows="2" autosize label="直播间公告" type="textarea" maxlength="50" placeholder="请输入直播间公告" show-word-limit :rules="[{ required: true, message: '请填写直播间公告' }]"/>
        <van-field name="img" label="直播间缩略图">
          <template #input>
            <van-uploader v-model="settingFrom.img" :after-read="afterEdit" :upload-text="'上传图片'" accept="image/*" :max-count="1" />
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 申请直播间 -->
    <van-popup v-model="applyShow" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="applySubmit" class="setting-from">
        <van-field v-model="applyFrom.name" name="name" label="直播间名称" placeholder="直播间名称" :rules="[{ required: true, message: '请填写直播间名称' }]"/>
        <van-field readonly clickable name="tid_name" label="归属分类" v-model="applyFrom.tid_name" placeholder="选择分类" @click="applyClassyify = true"/>
        <van-field v-model="applyFrom.introduction" name="introduction" rows="2" autosize label="直播间简介" type="textarea" maxlength="50" placeholder="请输入直播间简介" show-word-limit :rules="[{ required: true, message: '请填写直播间简介' }]"/>
        <van-field v-model="applyFrom.notification" name="notification" rows="2" autosize label="直播间公告" type="textarea" maxlength="50" placeholder="请输入直播间公告" show-word-limit :rules="[{ required: true, message: '请填写直播间公告' }]"/>
        <van-field name="img" label="直播间缩略图">
          <template #input>
            <van-uploader v-model="applyFrom.img" :after-read="afterRead" :upload-text="'上传图片'" accept="image/*" :max-count="1"/>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!--分类选择-->
    <van-popup v-model="applyClassyify" round position="bottom">
      <van-picker show-toolbar :columns="roomClassify" value-key="classify_title" @cancel="showPicker = false" @confirm="onAppyConfirm"/>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'LiveEnter',
  data () {
    return {
      applyStatus: 4, // 用户申请直播状态 1未申请 2.等待审核中 3. 已通过申请 4.申请被拒绝，但还可以继续申请
      settingShow: false,
      applyClassyify: false, // 申请房间选择分类
      roomClassify: [],
      settingFrom: {
        name: '',
        introduction: '',
        notification: '',
        img: [],
        unique: ''
      },
      applyFrom: {
        name: '',
        tid: '',
        tid_name: '',
        introduction: '',
        notification: '',
        img: []
      },
      applyShow: false,
      isOverTime: false // 房间是否已过期
    }
  },
  created () {
    // 获取直播间数据
    this.get_live_room_info()
    // 获取房间分类数据
    this.get_room_classify()
  },
  methods: {
    // 获取房间分类
    async get_room_classify () {
      const { data: res } = await this.$http.get('/index/room-classify')
      if (res.meta.status === 200) {
        this.roomClassify = res.data
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 申请房间-选择分类
    onAppyConfirm (value) {
      console.log(value.id)
      this.applyFrom.tid = value.id
      this.applyFrom.tid_name = value.classify_title
      this.applyClassyify = false
    },
    /**
     * 上传图片之后调用接口
     */
    async afterRead (file) {
      const fileinfo = new FormData()
      fileinfo.append('file', file.file, file.name)
      const { data: res } = await this.$http.post('/index/upload', fileinfo)
      if (res.meta.status === 200) {
        this.applyFrom.img[0].url = res.data
        this.$toast.success('上传成功')
      } else {
        this.$toast.fail('上传失败')
      }
    },
    /**
     * 上传图片之后调用接口
     */
    async afterEdit (file) {
      const fileinfo = new FormData()
      fileinfo.append('file', file.file, file.name)
      const { data: res } = await this.$http.post('/index/upload', fileinfo)
      if (res.meta.status === 200) {
        this.settingFrom.img[0].url = res.data
        this.$toast.success('上传成功')
      } else {
        this.$toast.fail('上传失败')
      }
    },
    async get_live_room_info () {
      const { data: res } = await this.$http.get('/index/live-room-info')
      if (res.meta.status === 200) {
        switch (res.data.room_status) {
          // 等待审核
          case 1 :
            this.applyStatus = 2
            break
          // 被拒绝
          case 2 :
            this.applyStatus = 4
            break
          // 正常
          case 3 :
            this.applyStatus = 3
        }
        // 判断直播间是否到期
        if (res.data.expire_time !== 0) {
          const expiredate = res.data.expire_time * 1000
          if (Date.now() > expiredate) {
            this.isOverTime = true
          }
        }
        this.settingFrom.name = res.data.live_title
        this.settingFrom.introduction = res.data.live_introduce
        this.settingFrom.notification = res.data.live_notice
        this.settingFrom.img = [{ url: res.data.live_avatar }]
        this.settingFrom.unique = res.data.unique
      } else if (res.meta.status === 301) {
        // 用户还未创建直播间
        this.applyStatus = 1
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
        this.$router.push('/user')
      }
    },
    onClickLeft () {
      this.$router.push('/user')
    },
    // 编辑直播间资料表单提交
    async editSubmit (values) {
      const { data: res } = await this.$http.put('/index/apply-live-room', values)
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        this.applyShow = false
        this.get_live_room_info()
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 申请直播间表单提交
    async applySubmit (values) {
      const { data: res } = await this.$http.post('/index/apply-live-room', this.applyFrom)
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        this.get_live_room_info()
        this.applyShow = false
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    toLive () {
      this.$router.push('/user/live-enter/live/' + this.settingFrom.unique)
    }
  }
}
</script>

<style scoped>
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.live-room{
  height: 100vh;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 60%);
  z-index: 100;
  position: fixed;
  left: 0;
  width: 100%;
}
.no-apply{}
.no-apply .tips{
  margin-top: 25vh;
  font-size: 30px;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.6;
}
.no-apply .apply-btn{
  color: #FFBB12;
  padding: 10px;
  font-size: 13px;
  margin: 10%;
  border-radius: 4px;
  text-align: center;
  background-color: #FFFFFF;
}
.examine{}
.examine .tips{
  margin-top: 25vh;
  text-align: center;
  opacity: 0.8;
}
.has-apply{
  margin-top: 20vh;
}
.has-apply .live-setting{
  color: #FFBB12;
  padding: 10px;
  font-size: 13px;
  margin: 10%;
  border-radius: 4px;
  text-align: center;
  background-color: #FFFFFF;
}
.has-apply .live-now{
  color: #FFBB12;
  padding: 10px;
  font-size: 13px;
  margin: 10%;
  border-radius: 4px;
  text-align: center;
  background-color: #FFFFFF;
}
.setting-from{
  padding: 20px;
}
.refuse{
  margin-top: 20vh;
  text-align: center;
}
.refuse .tips{
  opacity: 0.8;
}
.refuse .apply-btn{
  color: #FFBB12;
  padding: 10px;
  font-size: 13px;
  margin: 10%;
  border-radius: 4px;
  text-align: center;
  background-color: #FFFFFF;
}
.wave{
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
.waves {
  position: fixed;
  bottom: -4px;
  left: 0;
  width: 100%;
  z-index: 100;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform:rotate(200deg);
  }
  100%{
    transform: rotate(0);
  }
}
.rotate{
  transition: 0.5s;
  animation: rotate 10s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}
</style>
