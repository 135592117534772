<template>
  <div class="home">
    <router-view></router-view>
    <tabbar></tabbar>
  </div>
</template>

<script>
import MainTabBar from './project/common/MainTabBar'
export default {
  name: 'Home',
  components: {
    tabbar: MainTabBar
  }
}
</script>

<style scoped>

</style>
