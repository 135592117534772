<template>
  <div class="notice-page">
    <van-nav-bar :fixed="true" :placeholder="true" title="消息" right-text="清空消息" @click-right="clearMessage"/>
    <div class="notice-list">
      <template v-for="(item,index) in messageList" :index="index">
        <div class="item" :class="{ noread: !item.status }" v-if="item.type === 'INVITE_MSG'" @click="toTranslate(item.unique_id, index)" :key="index">
          <div class="left">
            <van-badge :content="noReadCount(item)" v-if="item.status === false">
              <div class="img" :style="{ 'backgroundImage':'url('+ translateImg +')' }"></div>
            </van-badge>
            <div class="img" :style="{ 'backgroundImage':'url('+ translateImg +')' }" v-else></div>
          </div>
          <div class="middle">
            <div class="title">
              翻译邀请消息
            </div>
            <div class="content">
              {{ item.from }} 邀请您加入房间 {{ item.room_name }}
            </div>
          </div>
          <div class="right">
            <div class="time">
              {{ dateFormat(item.time) }}
            </div>
          </div>
        </div>
        <div class="item" v-if="item.type === 'USER_MSG'" @click="toChat(item.from_id)" :key="index">
          <div class="left">
            <van-badge :content="noReadCount(item)" v-if="item.status === false">
              <div class="img" :style="{ 'backgroundImage':'url('+ item.from_avatar +')' }"></div>
            </van-badge>
            <div class="img" :style="{ 'backgroundImage':'url('+ item.from_avatar +')' }" v-else></div>
          </div>
          <div class="middle">
            <div class="title">
              {{ item.from_nick }}
            </div>
            <div class="content">
              {{ item.msg.substring(0,20) }}
            </div>
          </div>
          <div class="right">
            <div class="time">
              {{ dateFormat(item.time) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageList',
  data () {
    return {
      translateImg: require('../../../assets/img/translate.png'),
      messageList: []
    }
  },
  created () {
    // 判断用户是否已经登录
    this.checkLogin()
    this.messageList = this.$store.state.messageList
  },
  methods: {
    async checkLogin () {
      if (this.$store.state.userInfo.username && this.$store.state.userInfo.token) {
        const { data: res } = await this.$http.post('/index/check-token', { token: this.$store.state.userInfo.token })
        if (res.meta.status === 200) {
          // 检查用户token通过
        } else {
          this.$toast.fail('身份信息验证失败，请重新登录')
          // 跳转到登录页面
          this.$router.push('/login')
        }
      } else {
        this.$toast.fail('您还未登录，请先登录')
        // 跳转到登录页面
        this.$router.push('/login')
      }
    },
    toChat (id) {
      this.$router.push('/user/friends-chat/' + id)
    },
    // 被邀请到房间去翻译
    toTranslate (roomId, index) {
      this.$store.commit('messageReded', index)
      this.$router.push('home/meeting-enter/' + roomId + '/meeting')
    },
    // 清空聊天消息
    clearMessage () {
      this.$store.commit('messageClear')
      this.messageList = this.$store.state.messageList
      this.$toast.success('清空消息成功')
    },
    // 格式化时间
    dateFormat (time) {
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm:ss')
    },
    // 统计未读消息条数
    noReadCount (item) {
      if (item.history.length > 0) {
        const newArr = item.history.filter(function (items, index, arr) {
          if (items.status === false) {
            return items
          }
        })
        return newArr.length
      }
    }
  }
}
</script>

<style scoped>
/deep/.van-nav-bar{
  z-index: 100;
  background-color: #facc31;
}
/deep/.van-nav-bar__title{
  color: #FFFFFF;
}
/deep/.van-nav-bar__text{
  color: #FFFFFF;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.notice-list{}
.notice-list .item{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #FFFFFF;
  padding: 14px 6px;
  border-top: 1px solid #F1F6FA;
  position: relative;
}
.notice-list .noread:after{
  content: "";
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 38px;
}
.notice-list .item .left{
  width: 40px;
  height: 40px;
}
.notice-list .item .left .img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
}
.notice-list .item .middle{
  margin-left: 10px;
  flex: 1;
}
.notice-list .item .middle .title{
  font-size: 14px;
  font-weight: bold;
}
.notice-list .item .middle .content{
  font-size: 14px;
  opacity: 0.6;
  margin-top: 2px;
}
.notice-list .item .right{
  width: 100px;
  FONT-WEIGHT: 100;
  text-align: right;
}
.notice-list .item .right .time{
  font-size: 14px;
  opacity: 0.6;
}
</style>
