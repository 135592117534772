<template>
  <div class="search-bar">
    <van-search v-model="searchText" shape="round" background="#FFFFFF" placeholder="请输入搜索关键词"/>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data () {
    return {
      searchText: ''
    }
  }
}
</script>

<style scoped>

</style>
