<template>
  <div class="classify">
    <van-search v-model="query.seachwords" placeholder="请输入搜索关键词"  @search="onSearch" @cancel="onCancel"/>
    <van-tree-select height="55vw" :items="classifyList" :active-id.sync="active" :main-active-index.sync="active" @click-nav="getContent">
      <template #content>
        <div class="ad-img" v-if="ad">
          <img :src="ad"/>
        </div>
        <div class="title">会议</div>
        <div class="room-list">
          <div class="room-item" v-if="meetingList.length > 0">
            <div v-for="(meeting,index) in meetingList" :key="index" :index="index" @click="toMeeting(meeting.unique)">
              <div class="item-img" :style="{ 'backgroundImage':'url('+ meeting.room_avatar +')' }">
              </div>
              <div class="room-info">
                {{ meeting.room_title }}
              </div>
            </div>
          </div>
          <div class="no-item" v-else>暂无内容</div>
        </div>
        <div class="title">直播</div>
        <div class="room-list">
          <div class="room-item" v-if="liveList.length > 0">
            <div v-for="(live,index) in liveList" :key="index" :index="index" @click="toLive(live.unique)">
              <div class="item-img" :style="{ 'backgroundImage':'url('+ live.live_avatar +')' }">
              </div>
              <div class="room-info">
                {{ live.live_title }}
              </div>
            </div>
          </div>
          <div class="no-item" v-else>暂无内容</div>
        </div>
      </template>
    </van-tree-select>
  </div>
</template>

<script>
export default {
  name: 'ClassifyMain',
  data () {
    return {
      active: 0,
      classifyList: [],
      query: {
        id: 0,
        seachwords: ''
      },
      meetingList: [],
      liveList: [],
      ad: ''
    }
  },
  created () {
    this.getClassify()
    this.getContent(null)
  },
  methods: {
    async getClassify () {
      const { data: res } = await this.$http.get('/index/get-classify')
      if (res.meta.status === 200) {
        this.classifyList = res.data.classifyList
        this.classifyList.unshift({ id: '', text: '全部', classify_ad: null })
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 获取分类下的ID
    async getContent (index) {
      this.query.id = index ? this.classifyList[index].id : ''
      const { data: res } = await this.$http.get('/index/get-meeting-live-list', { params: this.query })
      if (res.meta.status === 200) {
        this.meetingList = res.data.meetinglist
        this.liveList = res.data.livelist
        this.ad = res.data.ad
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    onSearch () {
      this.getContent(this.query.id)
    },
    onCancel () {
      this.query.seachwords = ''
    },
    toMeeting (unique) {
      this.$router.push('/home/meeting-enter/' + unique)
    },
    toLive (unique) {
      this.$router.push('/home/live/' + unique)
    }
  }
}
</script>

<style scoped>
.classify .title{
  padding: 0 10px;
}
.no-item{
  padding: 20px;
  text-align: center;
  width: 100%;
  background-color: rgba(0,0,0,0.03);
  font-size: 14px;
  opacity: 0.4;
}
/deep/.van-tree-select{
  min-height: calc( 100vh - 104px);
}
.room-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4px;
  padding: 10px 10px 60px 10px;
}
.room-list .room-item{
  width: 48%;
  margin: 1%;
  background-color: #FFFFFF;
  border-radius: 4px 4px 0 0;
}
.room-list .room-item .item-img{
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 16px 40px rgba(112,144,176,0.2);
}
.room-list .room-item .room-info{
  font-size: 14px;
  padding: 12px 6px;
  text-align: left;
}
.ad-img{
  width: 100%;
  margin-bottom: 20px;
}
.ad-img img{
  width: 100%;
}
</style>
