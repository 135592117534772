<template>
  <div class="translate">
    <van-nav-bar :fixed="true" :placeholder="true" title="翻译人员" left-text="返回" right-text="申请成为翻译" left-arrow @click-left="onClickLeft" @click-right="onClickRight"/>
    <div class="search-bar">
      <van-search :fixed="true" v-model="value" placeholder="请输入搜索关键词" input-align="center"/>
    </div>
    <div class="translate-list">
      <!--翻译人员单例-->
      <div class="translate-item" v-for="(item, index) in translateList" :index="index" :key="item.id">
        <div class="user-avatr">
          <img :src="item.avatar"/>
        </div>
        <div class="user-info">
          <div class="user-name">
            {{ item.realname }}
            <span style="font-size: 12px;opacity: 0.6;">
              ( {{ item.nick }} )
            </span>
          </div>
          <div class="skill">
            <div class="skill-item" v-for="(skill, index2) in JSON.parse(item.language)" :index="index2" :key="index2">
             {{ skill | changeView }}
            </div>
          </div>
        </div>
        <div class="user-status" v-if="$store.state.userInfo.username === item.username">
          <van-tag type="primary" size="large" style="font-size: 12px;" @click="editInfo">编辑</van-tag>
          <van-tag type="danger" size="large" style="margin-top: 10px;font-size: 12px;" @click="delInfo">删除</van-tag>
        </div>
        <div class="user-status" v-else>
          <div class="busy" v-if="status === 1">
            <div class="dot"></div>
            <span>繁忙中</span>
          </div>
          <div class="free" v-if="status === 2">
            <div class="dot"></div>
            <span>空闲中</span>
          </div>
          <div class="offline" v-if="status === 3">
            <div class="dot"></div>
            <span>离线</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 申请成为翻译弹窗 -->
    <van-popup class="applyPop" v-model="applyShow" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="onApplySubmit">
        <van-field
          v-model="applyFrom.realname"
          name="realname"
          label="真实姓名"
          placeholder="请填写真实姓名"
          :rules="[{ required: true, message: '请填写真实姓名' }]"
        />
        <van-field name="avatar" label="工作头像" :rules="[{ required: true, message: '请上传工作头像' }]">
          <template #input>
            <van-uploader :upload-text="'上传图片'" :max-count="1" class="edit-arvat" v-model="applyFrom.avatar" :after-read="avatarUpload"></van-uploader>
          </template>
        </van-field>
        <van-field name="language" label="擅长语种" :rules="[{ required: true, message: '请选择擅长语种' }]">
          <template #input>
            <van-checkbox-group v-model="applyFrom.language" checked-color="#facc31" direction="horizontal" >
              <van-checkbox name="1" shape="square">英语</van-checkbox>
              <van-checkbox name="2" shape="square">日语</van-checkbox>
              <van-checkbox name="3" shape="square">韩语</van-checkbox>
              <van-checkbox name="4" shape="square">俄语</van-checkbox>
              <van-checkbox name="5" shape="square">法语</van-checkbox>
              <van-checkbox name="6" shape="square">德语</van-checkbox>
              <van-checkbox name="7" shape="square">西班牙语</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field name="certificate" label="技能证书（可上传多张）" :rules="[{ required: true, message: '请上传技能证书' }]">
          <template #input>
            <van-uploader :upload-text="'上传图片'" :max-count="5" class="edit-arvat" accept="image/jpg, image/jpeg" v-model="applyFrom.certificate" :after-read="certificateUpload"></van-uploader>
          </template>
        </van-field>
        <van-field
          name="introduce"
          v-model="applyFrom.introduce"
          rows="2"
          autosize
          label="自我介绍"
          type="textarea"
          maxlength="250"
          placeholder="请输入自我介绍"
          show-word-limit
          :rules="[{ required: true, message: '请输入自我介绍' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 修改翻译弹窗 -->
    <van-popup class="applyPop" v-model="editShow" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="onEditSubmit">
        <van-field
          v-model="editFrom.realname"
          name="realname"
          label="真实姓名"
          placeholder="请填写真实姓名"
          :rules="[{ required: true, message: '请填写真实姓名' }]"
        />
        <van-field name="avatar" label="工作头像" :rules="[{ required: true, message: '请上传工作头像' }]">
          <template #input>
            <van-uploader :upload-text="'上传图片'" :max-count="1" class="edit-arvat" v-model="editFrom.avatar" :after-read="editAvatarUpload"></van-uploader>
          </template>
        </van-field>
        <van-field name="language" label="擅长语种" :rules="[{ required: true, message: '请选择擅长语种' }]">
          <template #input>
            <van-checkbox-group v-model="editFrom.language" checked-color="#facc31" direction="horizontal" >
              <van-checkbox name="1" shape="square">英语</van-checkbox>
              <van-checkbox name="2" shape="square">日语</van-checkbox>
              <van-checkbox name="3" shape="square">韩语</van-checkbox>
              <van-checkbox name="4" shape="square">俄语</van-checkbox>
              <van-checkbox name="5" shape="square">法语</van-checkbox>
              <van-checkbox name="6" shape="square">德语</van-checkbox>
              <van-checkbox name="7" shape="square">西班牙语</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field name="certificate" label="技能证书（可上传多张）" :rules="[{ required: true, message: '请上传技能证书' }]">
          <template #input>
            <van-uploader :upload-text="'上传图片'" :max-count="5" class="edit-arvat" accept="image/jpg, image/jpeg" v-model="editFrom.certificate" :after-read="editCertificateUpload"></van-uploader>
          </template>
        </van-field>
        <van-field
          name="introduce"
          v-model="editFrom.introduce"
          rows="2"
          autosize
          label="自我介绍"
          type="textarea"
          maxlength="250"
          placeholder="请输入自我介绍"
          show-word-limit
          :rules="[{ required: true, message: '请输入自我介绍' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'TranslateList',
  data () {
    return {
      value: '',
      status: 2,
      applyShow: false,
      applyFrom: {
        realname: '',
        avatar: [],
        language: [],
        certificate: [],
        introduce: ''
      },
      editShow: false,
      editFrom: {
        realname: '',
        avatar: [],
        language: [],
        certificate: [],
        introduce: ''
      },
      translateList: []
    }
  },
  created () {
    this.getTranslateList()
  },
  methods: {
    // 编辑翻译信息
    async editInfo () {
      const { data: res } = await this.$http.get('/index/my-translate-info')
      if (res.meta.status === 200) {
        this.editFrom.realname = res.data.realname
        this.editFrom.avatar[0] = { url: res.data.avatar }
        this.editFrom.language = res.data.language
        this.editFrom.certificate = res.data.certificate
        this.editFrom.introduce = res.data.introduce
        this.editShow = true
      } else {
        this.$toast.fail('获取失败')
      }
    },
    // 删除翻译信息
    async delInfo () {
      const { data: res } = await this.$http.get('/index/del-translate-info')
      if (res.meta.status === 200) {
        this.getTranslateList()
        this.$toast.success(res.meta.msg)
      } else {
        this.$toast.fail('获取失败')
      }
    },
    async getTranslateList () {
      const { data: res } = await this.$http.get('/index/translate-list')
      if (res.meta.status === 200) {
        this.translateList = res.data
      } else {
        this.$toast.fail('获取失败')
      }
    },
    onClickLeft () {
      this.$router.push('/user')
    },
    onClickRight () {
      this.applyShow = true
    },
    async onApplySubmit (values) {
      const { data: res } = await this.$http.post('/index/apply-translate', values)
      if (res.meta.status === 200) {
        this.$notify({ type: 'success', message: '申请成功' })
        this.$forceUpdate()
        this.applyShow = false
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
      }
    },
    async onEditSubmit (values) {
      const { data: res } = await this.$http.post('/index/edit-translate', values)
      if (res.meta.status === 200) {
        this.$notify({ type: 'success', message: '修改成功' })
        this.$forceUpdate()
        this.getTranslateList()
        this.editShow = false
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
      }
    },
    async avatarUpload (file) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.applyFrom.avatar[0].url = res.data
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    },
    async editAvatarUpload (file) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.editFrom.avatar[0].url = res.data
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    },
    async certificateUpload (file, detail) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.applyFrom.certificate[detail.index] = { url: res.data }
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    },
    async editCertificateUpload (file, detail) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.editFrom.certificate[detail.index] = { url: res.data }
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    }
  },
  filters: {
    changeView: function (value) {
      if (!value) return ''
      value = Number(value)
      let text = ''
      switch (value) {
        case 1:
          text = '英语'
          break
        case 2:
          text = '日语'
          break
        case 3:
          text = '韩语'
          break
        case 4:
          text = '俄语'
          break
        case 5:
          text = '法语'
          break
        case 6:
          text = '德语'
          break
        case 7:
          text = '西班牙语'
          break
        default:
          text = ''
      }
      return text
    }
  }
}
</script>

<style scoped>
.applyPop{
  padding: 20px;
}
/deep/ .van-checkbox{
  margin: 4px;
}
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.translate{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #F1F6FA;
}
.search-bar{
  position: fixed;
  width: 100%;
  top: 46px;
  left: 0;
}
.translate-list{
  padding: 20px 20px 60px 20px;
  margin-top: 54px;
}
.translate-list .translate-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.translate-list .translate-item .user-avatr{
  width: 80px;
  text-align: left;
}
.translate-list .translate-item .user-avatr img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.translate-list .translate-item .user-info{
  flex: 1;
  text-align: left;
}
.translate-list .translate-item .user-info .user-name{
  font-size: 16px;
  font-weight: bold;
}
.translate-list .translate-item .user-info .skill{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.translate-list .translate-item .user-info .skill .skill-item{
  background-color: rgb(250, 204, 49);
  padding: 2px 4px;
  margin-right: 2px;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  margin-top: 10px;
}
.translate-list .translate-item .user-status{
  width: 60px;
  text-align: right;
}
.translate-list .translate-item .user-status .busy{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.translate-list .translate-item .user-status .busy .dot{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #facc31;
  margin-right: 4px;
}
.translate-list .translate-item .user-status .busy span{
  font-size: 12px;
  color: #facc31;
}
.translate-list .translate-item .user-status .free{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.translate-list .translate-item .user-status .free .dot{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #07c160;
  margin-right: 4px;
}
.translate-list .translate-item .user-status .free span{
  font-size: 12px;
  color: #07c160;
}
.translate-list .translate-item .user-status .offline{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.translate-list .translate-item .user-status .offline .dot{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #b7b7b7;
  margin-right: 4px;
}
.translate-list .translate-item .user-status .offline span{
  font-size: 12px;
  color: #b7b7b7;
}
</style>
