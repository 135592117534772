<template>
  <div id="app">
    <router-view></router-view>
    <audio id="chatMessageAudio">
      <source id="chatMessageAudioSource" src="https://chat.api.gljshz.com/static/tips.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
// import audiosource from './assets/mp3/tips.mp3'
export default {
  name: 'APP',
  data () {
    return {
      ws: this.wsCliten
      // mp3Src: audiosource
    }
  },
  created () {
    this.initWebSocket()
  },
  destroyed () {
    // 离开路由之后断开websocket连接
    this.websocketclose()
  },
  methods: {
    initWebSocket () {
      // 判断VUEX中client是否为空
      if (this.ws) {
        this.ws.onmessage = this.websocketonmessage
        this.ws.onopen = this.websocketonopen
        this.ws.onerror = this.websocketonerror
        this.ws.onclose = this.websocketclose
      } else {
        // 初始化weosocket
        const wsuri = 'wss://chat.ws.gljshz.com:8567'
        this.ws = new WebSocket(wsuri)
        this.$store.commit('updateClient', this.ws)
        this.ws.onmessage = this.websocketonmessage
        this.ws.onopen = this.websocketonopen
        this.ws.onerror = this.websocketonerror
        this.ws.onclose = this.websocketclose
      }
    },
    websocketonopen () { // 连接建立之后执行send方法发送数据
      const actions = { beat: 'heart beat' }
      this.websocketsend(JSON.stringify(actions))
      // 连接后,定时发送,否则不段时间不通信会自动断连(时间长短一般是服务端指定的)
      const that = this
      setInterval(function () {
        that.websocketsend(JSON.stringify(actions))
      }, 1000)
    },
    websocketonerror () { // 连接建立失败重连
      this.initWebSocket()
    },
    async websocketonmessage (e) { // 数据接收
      const redata = JSON.parse(e.data)
      if (redata.type === 'init') {
        this.$store.commit('updateClientId', redata.client_id)
        // 绑定UID
        this.bindUid(redata.client_id)
      } else if (redata.type === 'SYS_TIPS' || redata.type === 'INVITE_MSG' || redata.type === 'USER_MSG') {
        this.$store.commit('messageUpdate', redata)
        // 播放提示音
        const audio = document.getElementById('chatMessageAudio')
        const audioplay = audio.play()
        audioplay && audioplay.then(function () {}).catch(function (e) {
          console.log(e)
        })
        this.$toast.fail('您有新的未读消息')
      }
    },
    websocketsend (Data) { // 数据发送
      this.$store.state.wsInfo.client.send(Data)
    },
    async websocketclose (e) {
      // 关闭
      if (this.$store.state.wsInfo.client_id) {
        await this.$http.delete('/index/close-client/' + this.$store.state.wsInfo.client_id)
      }
    },
    // 绑定UID
    async bindUid (clientid) {
      // 判断sessionStorage中是否存在username（是否已登录），存在则开始绑定，否则不绑定
      const check = sessionStorage.getItem('username')
      if (check) {
        const { data: res } = await this.$http.post('/index/bind', { client_id: clientid })
        if (res.meta.status === 200) {
          // 更新VUEX中的uid
          this.$store.commit('updateUid', res.data)
        } else {
          this.$toast.fail(res.meta.msg)
        }
      } else {
        console.log('用户未登录')
      }
    }
  },
  computed: {
    wsCliten () {
      return this.$store.state.wsInfo.client
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body{
  padding: 0;
  margin: 0;
  background-color: #F1F6FA;
  box-sizing: border-box;
}
*{
  box-sizing: border-box;
}
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
</style>
