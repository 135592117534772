<template>
  <div class="banner">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="!bannerList">
      <van-swipe-item>
        <img src="../../../../assets/img/banner1.png"/>
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../../../assets/img/banner2.png"/>
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../../../assets/img/banner3.png"/>
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../../../assets/img/banner4.png"/>
      </van-swipe-item>
    </van-swipe>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-else>
      <van-swipe-item v-for="(item,index) in bannerList" :key="index" :index="index">
        <img :src="item.img" @click="toOutLink(item.link)"/>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data () {
    return {
      bannerList: []
    }
  },
  created () {
    this.getBannerList()
  },
  methods: {
    async getBannerList () {
      const { data: res } = await this.$http.get('/index/banner-list')
      if (res.meta.status === 200) {
        this.bannerList = res.data
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    toOutLink (link) {
      console.log(link)
      if (!link) {
        return false
      } else {
        window.location.href = link
      }
    }
  }
}
</script>

<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.my-swipe .van-swipe-item img{
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.05);
}
</style>
