<template>
  <div class="live">
    <div class="play-area">
      <div id="remote_stream" v-if="remoteShow">
        <div class="no-live" v-if="noLive">主播未在线</div>
      </div>
    </div>
    <van-tabs v-model="activeTab">
      <van-tab title="聊天">
        <div class="chat-list">
          <div class="item" v-for="(item,index) in messageList" :key="index" :index="index">
            <div class="system-msg" v-if="item.type === 'GRP_TIPS'">
              <span class="name">系统消息：</span>
              <span class="msg">{{ item.msg}}</span>
            </div>
            <div class="user-msg" v-else>
              <span class="name">{{ item.from_nick ? item.from_nick : item.from_user }}：</span>
              <span class="msg">{{ item.msg }}</span>
            </div>
          </div>
        </div>
        <div class="send-panel">
          <van-field v-model="sendMsg" placeholder="">
            <template #button>
              <van-button size="small" type="primary" @click="sendGroupMsg">发送</van-button>
            </template>
          </van-field>
        </div>
      </van-tab>
      <van-tab title="简介">
        <div class="introduction">
          {{ this.roomInfo.live_introduce }}
        </div>
      </van-tab>
    </van-tabs>
    <div class="header-tool">
      <div class="left" @click="leaveRoom">
        <img src="../../../../assets/img/back.png"/>
      </div>
      <div class="middle">{{ roomInfo.live_title }}</div>
      <div class="right">
        <div class="users-num">
          {{ count }} 人
        </div>
      </div>
    </div>
    <!--显示房间分辨率-->
    <div class="room-definition">
      <div class="text">
        <span>当前分辨率: </span>
        <span v-if="roomInfo.definition === 0">480P</span>
        <span v-else-if="roomInfo.definition === 1">720P</span>
        <span v-else-if="roomInfo.definition === 2">1080P</span>
        <span v-else-if="roomInfo.definition === 3">1440P</span>
        <span v-else-if="roomInfo.definition === 4">4k高清</span>
        <span v-else>获取失败</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleLive',
  data () {
    return {
      noLive: true,
      count: 0,
      sendMsg: '',
      messageList: [],
      websock: null,
      wsClientId: '',
      client: {},
      localStream: {},
      remoteStream: {},
      remoteShow: false,
      roomInfo: {
        live_title: '',
        live_introduce: '',
        live_notice: '',
        live_avatar: '',
        live_anchor: '',
        definition: 0 // 视频清晰度
      },
      userInfo: {
        username: '',
        appid: '',
        token: ''
      },
      activeTab: 0
    }
  },
  created () {
    this.getRoomInfo()
  },
  methods: {
    toggleIntroduce () {
      this.introduceShow = true
    },
    wsInit () {
      this.websock = new WebSocket('wss://chat.ws.gljshz.com:8567')
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
    },
    websocketonerror () { // 连接建立失败重连
      this.wsInit()
    },
    async websocketonmessage (e) { // 数据接收
      const data = JSON.parse(e.data)
      const that = this
      // 如果刷新页面 client_id 会变动，需要重新获取client_id
      if (data.type === 'init') {
        that.$store.commit('updateClientId', data.client_id)
        // 绑定uid 到 client_id
        const { data: res } = await that.$http.post('/index/bind', { client_id: that.$store.state.wsInfo.client_id, username: this.userInfo.username, token: this.userInfo.token })
        if (res.meta.status === 200) {
          that.wsJoinRoom()
        } else {
          that.$toast.fail(res.meta.msg)
        }
      } else if (data.type === 'GRP_TIPS' || data.type === 'GRP_MSG' || data.type === 'INVITE_MSG') {
        that.count = data.count
        that.messageList.push(data)
      }
    },
    // ws加入房间
    async wsJoinRoom () {
      // 如果vuex中有client_id则请求加入群组，如果不满足则通过 websocketonmessage 的 init 重新绑定到uid再加入群组
      if (this.$store.state.wsInfo.client_id) {
        await this.$http.post('/index/ws-join-group', { client_id: this.$store.state.wsInfo.client_id, group_id: this.$route.params.unique })
      }
    },
    // WS发送群组消息
    async sendGroupMsg () {
      if (this.sendMsg) {
        await this.$http.post('/index/send-group-msg', { group_id: this.$route.params.unique, msg: this.sendMsg })
        this.sendMsg = ''
      } else {
        this.$toast.fail('请填写要发送的内容')
      }
    },
    // WS退出房间
    async wsLeaveGroup () {
      const { data: res } = await this.$http.post('/index/ws-leave-group', { client_id: this.$store.state.wsInfo.client_id, group_id: this.$route.params.unique })
      if (res.meta.status === 200) {
        window.location.href = 'about:blank'
        window.close()
      }
    },
    // 获取直播间数据
    async getRoomInfo () {
      const { data: res } = await this.$http.get('/index/index-nologin-live-room-info/' + this.$route.params.unique)
      if (res.meta.status === 200) {
        this.roomInfo.live_title = res.data.live_title
        this.roomInfo.live_introduce = res.data.live_introduce
        this.roomInfo.live_notice = res.data.live_notice
        this.roomInfo.live_avatar = res.data.live_avatar
        this.roomInfo.live_anchor = res.data.live_anchor
        this.roomInfo.definition = res.data.definition
        this.userInfo.username = res.data.username
        this.userInfo.token = res.data.token
        this.userInfo.appid = res.data.SDKAppID
        this.createClient()
        window.sessionStorage.setItem('username', res.data.username)
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('SDKAppID', res.data.SDKAppID)
        // 消息监听 WS
        this.wsInit()
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 创建 Client 对象
    createClient () {
      this.client = this.$TRTC.createClient({
        mode: 'live',
        sdkAppId: this.userInfo.appid,
        userId: this.userInfo.username,
        userSig: this.userInfo.token,
        useStringRoomId: true
      })
      this.$TRTC.Logger.setLogLevel(this.$TRTC.Logger.LogLevel.ERROR)
      this.subscribe()
    },
    // 订阅远端音视频流
    subscribe () {
      const that = this
      that.remoteShow = true
      that.client.on('stream-added', event => {
        console.log('------subscribe 远端流增加 成功------')
        const remoteStream = event.stream
        that.remoteStream = remoteStream
        // console.log('远端流增加: ' + remoteStream.getId())
        // 订阅远端流
        this.client.subscribe(remoteStream)
      })
      that.client.on('stream-subscribed', event => {
        console.log('------subscribe 远端流订阅成功 成功------')
        const remoteStream = event.stream
        that.remoteStream = remoteStream
        // console.log('远端流订阅成功：' + remoteStream.getId())
        // 播放远端流
        remoteStream.play('remote_stream', { objectFit: 'contain' })
        that.noLive = false
      })
      that.joinRoom()
    },
    //  进入房间
    joinRoom () {
      console.log('------joinRoom 成功------')
      this.client.join({ roomId: this.$route.params.unique, role: 'audience' })
        .catch(error => {
          console.error('进房失败 ' + error)
        })
        .then(() => {
          console.log('进房成功')
        })
    },
    // 退出房间
    leaveRoom () {
      const that = this
      // 取消发布本地流
      that.client.leave().then(() => {
        // leaving room success
        that.$toast.success('退出房间成功！，请稍等')
        that.$router.push('/home')
      }).catch(error => {
        that.$toast.fail('退出房间失败！' + error)
      })
    }
  },
  updated () {
    this.$nextTick(() => {
      var container = this.$el.querySelector('.chat-list')
      container.scrollTop = container.scrollHeight
    })
  }
}
</script>

<style scoped>
.room-definition{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #ed6a0c;
  text-align: center;
}
.room-definition .text{
  background-color: #fffbe8;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
}
#remote_stream{
  height: 400px;
}
.user-live{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #F1F6FA;
  z-index: 100;
}
/deep/.van-tab__pane, .van-tab__pane-wrapper{
  position: relative;
}
.send-panel{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.chat-list{
  width: 100%;
  height: calc(100vh - 500px);
  overflow-y: scroll;
  padding: 20px;
}
.chat-list .item{
  font-size: 14px;
  padding: 4px 0;
}
.chat-list .item .system-msg{
  color: #facc31;
}
.chat-list .item .user-msg{}
.chat-list .item .user-msg .name{
  color: #07c160;
}
.chat-list .item .msg{
  max-width: 100%;
  word-wrap: break-word;
  word-break: normal;
}
.introduction{
  padding: 20px;
}
.header-tool{
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.header-tool .left img{
  width: 40px;
  height: 40px;
}
.header-tool .middle{
  font-size: 16px;
  font-weight: bold;
}
.header-tool .right{}
.header-tool .right .users-num{
  font-size: 14px;
  background-color: rgba(255,255,255,0.8);
  padding: 4px 10px;
  border-radius: 30px;
}
.no-live{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
</style>
