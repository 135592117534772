<template>
  <div class="room-list">
    <div class="tab-bar">
      <div class="item" :class="{'active' : activeSort === 'meeting'}" @click="changeSort('meeting')">
        <span class="title">会议</span>
        <span class="bt-line"></span>
      </div>
      <div class="item" :class="{'active' : activeSort === 'live'}" @click="changeSort('live')">
        <span class="title">直播</span>
        <span class="bt-line"></span>
      </div>
    </div>
    <div class="room-panel" v-if="activeSort === 'live'">
      <div class="room-item" v-for="item in roomList.liveList" :key="item.id" :index="item.id" @click="toLive(item.unique)">
        <div class="img">
          <img :src="item.live_avatar"/>
        </div>
        <div class="info">
          <div class="title">{{ item.live_title }}</div>
        </div>
        <div class="tags">
          直播
        </div>
      </div>
    </div>
    <div class="room-panel" v-else>
      <div class="room-item" v-for="item in roomList.meetingList" :key="item.id" :index="item.id" @click="toMeeting(item.unique)">
        <div class="img">
          <img :src="item.room_avatar"/>
        </div>
        <div class="info">
          <div class="title">{{ item.room_title }}</div>
        </div>
        <div class="tags meeting">
          会议
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomList',
  data () {
    return {
      activeSort: 'meeting',
      roomList: {
        meetingList: [],
        liveList: []
      }
    }
  },
  created () {
    this.get_room_list()
  },
  methods: {
    async get_room_list () {
      const { data: res } = await this.$http.get('/index/index-get-all-room')
      if (res.meta.status === 200) {
        this.roomList.meetingList = res.data.meeting
        this.roomList.liveList = res.data.live
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    changeSort (key) {
      this.activeSort = key
    },
    toMeeting (unique) {
      this.$router.push('/home/meeting-enter/' + unique)
    },
    toLive (unique) {
      this.$router.push('/home/live/' + unique)
    }
  }
}
</script>

<style scoped>
.room-list{}
.tab-bar{
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
}
.tab-bar .item{
  margin: 0 10px;
  cursor: pointer;
}
.tab-bar .item .title{
  font-size: 16px;
}
.tab-bar .item .bt-line{}
.tab-bar .active{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tab-bar .active .title{
  font-weight: bold;
  font-size: 18px;
}
.tab-bar .active .bt-line{
  width: 30px;
  height: 4px;
  background-color: #FFBB12;
  border-radius: 4px;
  margin-top: 4px;
}
.room-panel{
  padding: 10px 10px 60px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.room-item{
  width: 46%;
  height: auto;
  border-radius: 6px;
  background-color: #FFFFFF;
  position: relative;
  box-shadow: 0 0 3px 1px rgba(0,0,0,0.05);
  margin: 0 2% 16px 2%;
  cursor: pointer;
}
.room-item .img{
  width: 100%;
}
.room-item .img img{
  width: 100%;
  border-radius: 6px 6px 0 0;
}
.room-item .info{
  padding: 10px;
}
.room-item .info .title{
  font-size: 14px;
  font-weight: bold;
}
.room-item .tags{
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #FFBB12;
  font-size: 14px;
  padding: 2px 14px;
  border-radius: 0 6px 6px 0;
  color: #FFFFFF;
}
.room-item .meeting{
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #2798FF;
  font-size: 14px;
  padding: 2px 14px;
  border-radius: 0 6px 6px 0;
}
</style>
