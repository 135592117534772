<template>
  <div class="meeting-home">
    <van-nav-bar :fixed="true" :placeholder="true" title="房间管理" left-text="返回" right-text="申请房间" left-arrow @click-left="onClickLeft" @click-right="onClickRight"/>
    <div class="room-list">
      <div class="room-item" :class="{'active' : activeItem === index}" v-for="(item,index) in roomList" :key="index">
        <div class="card" @click="toggle(index)">
          <div class="img" :style="{backgroundImage: 'url(' + item.room_avatar + ')' }"></div>
          <div class="info">
            <div class="title">{{ item.room_title }}</div>
            <div class="end-time" v-if="item.expire_time !== 0">到期时间：{{ dateFormat(item.expire_time) }}</div>
            <div class="end-time" v-else>到期时间：永久</div>
          </div>
          <div class="tag">
            <div class="error" v-if="item.room_status === 3">未通过</div>
            <div class="success" v-if="item.room_status === 2">已通过</div>
            <div class="examine" v-if="item.room_status === 1">审核中</div>
          </div>
        </div>
        <div class="tool" v-show="activeItem === index">
          <div class="item" @click="enterRoom(item.unique)">
            <img src="../../../../assets/img/enterlink.png"/>
            <span>进入房间</span>
          </div>
          <div class="item" @click="editItem(item.unique)">
            <img src="../../../../assets/img/edit.png"/>
            <span>编辑</span>
          </div>
          <div class="item" @click="deleteItem(item.unique)">
            <img src="../../../../assets/img/delete.png"/>
            <span>删除</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 申请新房间 -->
    <van-popup v-model="applyShow" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="onSubmit" class="applay-from">
        <van-field readonly clickable name="classify_title" label="归属分类" v-model="applyFrom.classify_title" placeholder="选择分类" @click="applyClassyify = true"/>
        <van-field v-model="applyFrom.room_title" name="room_title" label="会议名称" placeholder="会议名称" :rules="[{ required: true, message: '请填写会议名称' }]"/>
        <van-field v-model="applyFrom.room_introduce" name="room_introduce" rows="2" autosize label="会议简介" type="textarea" maxlength="50" placeholder="请输入会议简介" show-word-limit/>
        <van-field v-model="applyFrom.room_notice" name="room_notice" rows="2" autosize label="会议公告" type="textarea" maxlength="50" placeholder="请输入会议公告" show-word-limit/>
        <van-field name="room_avatar" label="缩略图">
          <template #input>
            <van-uploader v-model="applyFrom.room_avatar" :after-read="afterAdd" :upload-text="'上传图片'" accept="image/*" :max-count="1"/>
          </template>
        </van-field>
        <!-- 允许输入正整数，调起纯数字键盘 -->
        <van-field name="max_person" v-model="applyFrom.max_person" type="digit" label="最大参会人数" />
        <van-field name="apply" label="申请参会方式">
          <template #input>
            <van-radio-group v-model="applyFrom.apply" direction="horizontal">
              <van-radio :name="1">自由加入</van-radio>
              <van-radio :name="2">需要验证</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 编辑房间 -->
    <van-popup v-model="editShow" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="editSubmit" class="applay-from">
        <van-field readonly clickable name="classify_title" label="归属分类" v-model="editFrom.classify_title" placeholder="选择分类" @click="editClassyify = true"/>
        <van-field v-model="editFrom.room_title" name="room_title" label="会议名称" placeholder="会议名称" :rules="[{ required: true, message: '请填写会议名称' }]"/>
        <van-field v-model="editFrom.room_introduce" name="room_introduce" rows="2" autosize label="会议简介" type="textarea" maxlength="50" placeholder="请输入会议简介" show-word-limit/>
        <van-field v-model="editFrom.room_notice" name="room_notice" rows="2" autosize label="会议公告" type="textarea" maxlength="50" placeholder="请输入会议公告" show-word-limit/>
        <van-field name="room_avatar" label="缩略图">
          <template #input>
            <van-uploader v-model="editFrom.room_avatar" :after-read="afterEdit" :upload-text="'上传图片'" accept="image/*" :max-count="1"/>
          </template>
        </van-field>
        <!-- 允许输入正整数，调起纯数字键盘 -->
        <van-field name="max_person" v-model="editFrom.max_person" type="digit" label="最大参会人数" />
        <van-field name="apply" label="申请参会方式">
          <template #input>
            <van-radio-group v-model="editFrom.apply" direction="horizontal">
              <van-radio :name="1">自由加入</van-radio>
              <van-radio :name="2">需要验证</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!--样式-->
    <div class="bg-img"></div>
    <div class="wave">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
        </g>
      </svg>
    </div>
    <!--申请房间-分类选择-->
    <van-popup v-model="applyClassyify" round position="bottom">
      <van-picker show-toolbar :columns="roomClassify" value-key="classify_title" @cancel="showPicker = false" @confirm="onAppyConfirm"/>
    </van-popup>
    <!--编辑房间-分类选择-->
    <van-popup v-model="editClassyify" round position="bottom">
      <van-picker show-toolbar :columns="roomClassify" value-key="classify_title" @cancel="showPicker = false" @confirm="onEditConfirm"/>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'MeetingHome',
  data () {
    return {
      applyClassyify: false, // 申请房间选择分类
      editClassyify: false, // 修改房间选择分类
      roomClassify: [],
      activeItem: -1, // 初始化点击的索引值
      // 申请新房间数据
      applyFrom: {
        tid: '',
        classify_title: '',
        room_title: '',
        room_introduce: '',
        room_notice: '',
        room_avatar: [],
        max_person: 100,
        apply: 1
      },
      editFrom: {
        id: '',
        tid: '',
        classify_title: '',
        room_title: '',
        room_introduce: '',
        room_notice: '',
        room_avatar: [],
        max_person: '',
        unique: '',
        apply: 1
      },
      applyShow: false,
      editShow: false,
      roomList: []
    }
  },
  created () {
    this.get_meeting_room_list()
    // 获取房间分类数据
    this.get_room_classify()
  },
  methods: {
    // 获取房间分类
    async get_room_classify () {
      const { data: res } = await this.$http.get('/index/room-classify')
      if (res.meta.status === 200) {
        this.roomClassify = res.data
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 申请房间-选择分类
    onAppyConfirm (value) {
      this.applyFrom.tid = value.id
      this.applyFrom.classify_title = value.classify_title
      this.applyClassyify = false
    },
    onEditConfirm (value) {
      this.editFrom.tid = value.id
      this.editFrom.classify_title = value.classify_title
      this.editClassyify = false
    },
    // 格式化时间
    dateFormat (time) {
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm:ss')
    },
    // 获取房间列表数据
    async get_meeting_room_list () {
      const { data: res } = await this.$http.get('/index/user-meeting-lsit')
      if (res.meta.status === 200) {
        this.roomList = res.data
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    onClickLeft () {
      this.$router.push('/user')
    },
    onClickRight () {
      this.$toast('申请房间')
      this.applyShow = true
    },
    toggle (index) {
      if (this.activeItem !== -1) {
        this.activeItem = -1
      } else {
        this.activeItem = index
      }
    },
    // 进入房间
    enterRoom (unique) {
      this.$router.push('/user/meeting-home/meeting-enter/' + unique)
    },
    // 编辑
    async editItem (unique) {
      const { data: res } = await this.$http.get('/index/users-meeting-room', { params: { unique: unique } })
      if (res.meta.status === 200) {
        this.editFrom.id = res.data.id
        this.editFrom.tid = res.data.tid
        this.editFrom.classify_title = res.data.classify_title
        this.editFrom.room_title = res.data.room_title
        this.editFrom.room_introduce = res.data.room_introduce
        this.editFrom.room_notice = res.data.room_notice
        this.editFrom.room_avatar = [{ url: res.data.room_avatar }]
        this.editFrom.room_avatar = [{ url: res.data.room_avatar }]
        this.editFrom.max_person = res.data.max_person
        this.editFrom.apply = res.data.apply
        this.editFrom.unique = res.data.unique
        this.editShow = true
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 删除
    async deleteItem (unique) {
      const { data: res } = await this.$http.delete('/index/users-meeting-room', { params: { unique: unique } })
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        this.get_meeting_room_list()
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 申请新房间表单提交
    async onSubmit (values) {
      const { data: res } = await this.$http.post('/index/users-meeting-room', this.applyFrom)
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        this.get_meeting_room_list()
        this.applyShow = false
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 修改房间表单提交
    async editSubmit (values) {
      const { data: res } = await this.$http.put('/index/users-meeting-room', this.editFrom, { params: { unique: this.editFrom.unique } })
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        this.get_meeting_room_list()
        this.editShow = false
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    async afterAdd (file) {
      const fileinfo = new FormData()
      fileinfo.append('file', file.file, file.name)
      const { data: res } = await this.$http.post('/index/upload', fileinfo)
      if (res.meta.status === 200) {
        this.applyFrom.room_avatar[0].url = res.data
        this.$toast.success('上传成功')
      } else {
        this.$toast.fail('上传失败')
      }
    },
    async afterEdit (file) {
      const fileinfo = new FormData()
      fileinfo.append('file', file.file, file.name)
      const { data: res } = await this.$http.post('/index/upload', fileinfo)
      if (res.meta.status === 200) {
        this.editFrom.room_avatar[0].url = res.data
        this.$toast.success('上传成功')
      } else {
        this.$toast.fail('上传失败')
      }
    }
  }
}
</script>

<style scoped>
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.meeting-home{
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 60%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100vh;
}
.room-list{
  padding: 10px 10px 60px 10px;
  min-height: calc(100vh - 96px);
}
.room-list .room-item{
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 6px;
  position: relative;
  margin-bottom: 10px;
}
.room-list .active{
  margin-bottom: 50px;
}
.room-list .room-item .card{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 2px 1px rgba(0,0,0,0.05);
  position: relative;
  z-index: 10;
}
.room-list .room-item .img{
  width: 80px;
  height: 80px;
  border-radius: 6px 0 0 6px;
  background-size: cover;
}
.room-list .room-item .info{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 60px;
  justify-content: space-between;
}
.room-list .room-item .info .title{
  font-size: 16px;
  font-weight: bold;
}
.room-list .room-item .info .end-time{
  font-size: 14px;
  opacity: 0.6;
  margin-top: 6px;
}
.room-list .room-item .tag{
  position: absolute;
  top: 0;
  right: 0;
}
.room-list .room-item .tag .error{
  font-size: 14px;
  background-color: #F56C6C;
  color: #FFFFFF;
  padding: 2px 4px;
  border-radius: 0 6px;
}
.room-list .room-item .tag .success{
  font-size: 14px;
  background-color: #67C23A;
  color: #FFFFFF;
  padding: 2px 4px;
  border-radius: 0 6px;
}
.room-list .room-item .tag .examine{
  font-size: 14px;
  background-color: #E6A23C;
  color: #FFFFFF;
  padding: 2px 4px;
  border-radius: 0 6px;
}
.room-list .room-item .tool{
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
  display: flex;
  height: 40px;
  z-index: 9;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: space-around;
  border-radius: 0 0 6px 6px;
}
.room-list .room-item .tool .item{
  display: flex;
  align-items: center;
  justify-content: center;
}
.room-list .room-item .tool .item span{
  font-size: 13px;
  margin-left: 4px;
}
.room-list .room-item .tool .item img{
  width: 20px;
}
.applay-from{
  padding: 20px;
}
.wave{
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
.waves {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 100;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform:rotate(200deg);
  }
  100%{
    transform: rotate(0);
  }
}

.rotate{
  transition: 0.5s;
  animation: rotate 10s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}
</style>
