import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Login from '../components/authority/Login'
import Register from '../components/authority/Register'
import RestPd from '../components/authority/RestPd'
import HomeMain from '../components/project/home/HomeMain'
import ClassifyMain from '../components/project/classify/ClassifyMain'
import MessageList from '../components/project/message/MessageList'
import UserMain from '../components/project/user/UserMain'
// 个人中心-会议管理
import MeetingHome from '../components/project/user/meeting/MeetingHome'
// 会议房间介绍页面
import MeeingEnter from '../components/project/user/meeting/MeeingEnter'
// 会议通话界面
import Meeting from '../components/project/user/meeting/Meeting'
// 直播介绍页面
import LiveEnter from '../components/project/user/live/LiveEnter'
// 直播通话界面
import Live from '../components/project/user/live/Live'
// 好友
import FriendsList from '../components/project/user/widgets/FriendsList'
// 与好友聊天
import FriendsChat from '../components/project/user/widgets/FriendsChat'
// 翻译
import TranslateList from '../components/project/user/widgets/TranslateList'
// 首页进入会议室入口页面
import UserMeetingEnter from '../components/project/home/meeting/UserMeetingEnter'
// 用户进入会议室主页面
import UserMeeting from '../components/project/home/meeting/UserMeeting'
// 独立会议页面
import SingleMeeting from '../components/project/home/meeting/SingleMeeting'
// 用户进入直播间主页面
import UserLive from '../components/project/home/live/UserLive'
// 独立直播页面
import SingleLive from '../components/project/home/live/SingleLive'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/reset-pd', name: 'RestPd', component: RestPd },
  {
    path: '/',
    name: 'Home',
    redirect: '/home',
    component: Home,
    children: [
      { path: 'home', name: 'HomeMain', component: HomeMain },
      { path: 'classify', name: 'ClassifyMain', component: ClassifyMain },
      { path: 'message', name: 'MessageList', component: MessageList },
      { path: 'user', name: 'UserMain', component: UserMain },
      { path: 'user/meeting-home', name: 'MeetingHome', component: MeetingHome },
      { path: 'user/meeting-home/meeting-enter/:unique', name: 'MeeingEnter', component: MeeingEnter },
      { path: 'user/meeting-home/meeting-enter/:unique/meeting', name: 'Meeting', component: Meeting },
      { path: 'user/live-enter', name: 'LiveEnter', component: LiveEnter },
      { path: 'user/live-enter/live/:unique', name: 'Live', component: Live },
      { path: 'user/friends', name: 'FriendsList', component: FriendsList },
      { path: 'user/friends-chat/:id', name: 'FriendsChat', component: FriendsChat },
      { path: 'user/translate', name: 'TranslateList', component: TranslateList },
      { path: 'home/meeting-enter/:unique', name: 'UserMeetingEnter', component: UserMeetingEnter },
      { path: 'home/meeting-enter/:unique/meeting', name: 'UserMeeting', component: UserMeeting },
      { path: 'home/live/:unique', name: 'UserLive', component: UserLive }
    ]
  },
  { path: '/single-meeting/:unique', name: 'SingleMeeting', component: SingleMeeting },
  { path: '/single-live/:unique', name: 'SingleLive', component: SingleLive }
]

const router = new VueRouter({
  routes
})

export default router
