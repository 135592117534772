<template>
  <div class="login">
    <div class="information">
      <div class="bg-img"></div>
      <div class="wave">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
          </g>
        </svg>
      </div>
      <div class="login-panel">
        <div class="panel-title">用户未登录</div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="loginFrom.username"
            name="username"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="loginFrom.password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div class="more-info">
            <div class="item" @click="toFindPassword">忘记密码？</div>
            <div class="item" @click="toRegister">注册账号</div>
          </div>
          <div style="margin: 16px;">
            <van-button round block type="info" color="#FFFFFF" native-type="submit">登录</van-button>
          </div>
          <div class="to-home" @click="toHome">
            再逛逛,暂不登录
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loginFrom: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    // 登录提交表单按钮
    async onSubmit (values) {
      const { data: res } = await this.$http.post('/index/login', values)
      if (res.meta.status === 200) {
        this.$store.commit('login', res.data)
        this.$notify({ type: 'success', message: '登录成功' })
        window.sessionStorage.setItem('username', res.data.username)
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('SDKAppID', res.data.SDKAppID)
        // 获取缓存的上一页面路由
        const oldPath = sessionStorage.getItem('oldPath')
        this.$router.push(oldPath)
        this.$forceUpdate()
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
      }
    },
    toFindPassword () {
      this.$router.push('/reset-pd')
    },
    toRegister () {
      this.$router.push('/register')
    },
    toHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.to-home{
  font-size: 14px;
  margin-top: 30px;
}
.login .information{
  height: 100vh;
}
.information{
  position: relative;
  text-align: center;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 100%);
  color: white;
  width: 100%;
  height: 20vh;
}
.wave{
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}
.login-panel{
  padding: 40px;
}
.login-panel .panel-title{
  font-size: 30px;
  opacity: 0.4;
  padding: 40px 0;
}
.login-panel .more-info{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.login-panel .more-info .item{
  color: #fbfbfb;
  font-size: 14px;
  margin-left: 16px;
}
/deep/.login-panel .van-button__text{
  color: #FFBB12;
}
/deep/.login-panel .van-cell{
  margin: 10px 0;
  border-radius: 4px;
}
.no-login{
}
.no-login .information{
  height: calc(100vh - 50px);
}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
.waves {
  height:40px;
  min-height:40px;
  width: 100%;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform:rotate(200deg);
  }
  100%{
    transform: rotate(0);
  }
}

.rotate{
  transition: 0.5s;
  animation: rotate 10s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}
</style>
