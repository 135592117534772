<template>
  <div class="meeting-enter">
    <van-nav-bar :fixed="true" :placeholder="true" title="进入会议室" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <!--会议公告-->
    <van-notice-bar left-icon="volume-o" scrollable :text="roomInfo.room_notice"/>
    <!--会议简介-->
    <div class="room-introduce">
      <div class="introduce-content">
        {{ roomInfo.room_introduce }}
      </div>
    </div>
    <!-- 会议已过期 -->
    <div class="over-time" v-if="roomInfo.status === 2">
      <div class="tips">
        抱歉，该会议室已过期，请联系管理员更续时长
      </div>
      <van-button class="back-btn" round block type="info" color="#FFFFFF" @click="onClickLeft">返 回</van-button>
    </div>
    <!-- 会议进行中 -->
    <div class="meeting-progress" v-if="roomInfo.status === 1">
      <van-button class="enter-btn" round block type="info" color="#FFFFFF" @click="enterRoom">点击进入</van-button>
    </div>
    <div class="wave">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userMeetingEnter',
  data () {
    return {
      roomInfo: {
        room_notice: '',
        room_introduce: '',
        status: 1 // 1正常 2,已过期
      }
    }
  },
  created () {
    this.get_room_info()
  },
  methods: {
    async get_room_info () {
      const { data: res } = await this.$http.get('/index/get-single-meeting', { params: { unique: this.$route.params.unique } })
      if (res.meta.status === 200) {
        this.roomInfo.room_notice = res.data.room_notice
        this.roomInfo.room_introduce = res.data.room_introduce
        const expiredate = res.data.expire_time * 1000
        if (res.data.expire_time !== 0 && Date.now() < expiredate) {
          this.roomInfo.status = 2
        }
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    enterRoom () {
      this.$router.push('/home/meeting-enter/' + this.$route.params.unique + '/meeting')
    },
    onClickLeft () {
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.meeting-enter{
  height: 100vh;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 60%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.room-introduce{
  padding: 40px;
}
.introduce-content{
  border: 1px dashed rgba(255,255,255,0.4);
  border-radius: 6px;
  padding: 12px;
}
.wave{
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
.waves {
  position: fixed;
  bottom: -4px;
  left: 0;
  width: 100%;
  z-index: 100;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform:rotate(200deg);
  }
  100%{
    transform: rotate(0);
  }
}
.rotate{
  transition: 0.5s;
  animation: rotate 10s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}
.over-time{
  text-align: center;
  padding: 60px 20px;
  font-size: 14px;
}
.over-time .tips{}
.over-time .back-btn{
  margin-top: 30px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  color: #facc31!important;
}
.meeting-progress{}
.meeting-progress .tips{}
.meeting-progress .enter-btn{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #facc31!important;
}
</style>
