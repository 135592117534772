import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      username: '',
      token: '',
      appid: ''
    },
    wsInfo: {
      client: null,
      client_id: null,
      uid: null
    },
    messageList: []
  },
  mutations: {
    login (state, userInfo) {
      state.userInfo.username = userInfo.username
      state.userInfo.token = userInfo.token
      state.userInfo.appid = userInfo.SDKAppID
    },
    logout (state) {
      state.userInfo.username = ''
      state.userInfo.token = ''
      state.userInfo.appid = ''
    },
    updateClient (state, client) {
      state.wsInfo.client = client
    },
    updateClientId (state, clientid) {
      state.wsInfo.client_id = clientid
    },
    updateUid (state, uid) {
      state.wsInfo.uid = uid
    },
    wsLogout (state) {
      state.wsInfo.client = null
      state.wsInfo.client_id = null
      state.wsInfo.uid = null
    },
    messageUpdate (state, message) {
      message.status = false
      if (state.messageList.length > 0) {
        state.messageList.forEach(function (item, index) {
          if (message.from_id === item.from_id && message.type === item.type) {
            item.history.push(message)
            item.count = message.count
            item.from_avatar = message.from_avatar
            item.from_id = message.from_id
            item.from_nick = message.from_nick
            item.from_user = message.from_user
            item.msg = message.msg
            item.room_name = message.room_name
            item.status = message.status
            item.time = message.time
            item.to_id = message.to_id
            item.type = message.type
            item.unique_id = message.unique_id
            state.messageList[index] = item
          } else {
            message.status = false
            message.history = []
            state.messageList.push(message)
          }
        })
      } else {
        message.status = false
        message.history = []
        state.messageList.push(message)
      }
    },
    // 更改消息状态
    messageReded (state, index) {
      console.log(index)
      state.messageList[index].status = true
      // 还需要更改所有子消息历史的消息状态
      if (state.messageList[index].history.length > 0) {
        state.messageList[index].history.forEach(function (item, index2) {
          item.status = true
          state.messageList[index].history[index2] = item
        })
      }
    },
    // 清空消息记录
    messageClear (state) {
      state.messageList = []
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['userInfo', 'messageList']
  })]
})
