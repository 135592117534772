<template>
  <div class="meeting">
    <div class="video-list">
      <div id="local_stream"></div>
      <div id="remote_stream" v-if="remoteShow"></div>
    </div>
    <div class="menu-float">
      <div class="header">
        <div class="left" @click="leaveRoom">
          <img src="../../../../assets/img/back.png"/>
        </div>
        <div class="middle">{{ roomInfo.room_title }}</div>
        <div class="right">
          <div class="users-num">
            {{ count }} 人
          </div>
        </div>
      </div>
      <van-notice-bar scrollable left-icon="volume-o" :text="roomInfo.room_notice"/>
      <div class="footer">
        <div class="main-icon">
          <div class="item" @click="trunMic">
            <img v-if="callStatus.mic" src="../../../../assets/img/mic-on.png"/>
            <img v-else src="../../../../assets/img/mic-off.png"/>
          </div>
          <div class="item" @click="leaveRoom">
            <img src="../../../../assets/img/hand-off.png"/>
          </div>
          <div class="item" @click="trunCamera">
            <img v-if="callStatus.camera" src="../../../../assets/img/camera-on.png"/>
            <img v-else src="../../../../assets/img/camera-off.png"/>
          </div>
        </div>
        <div class="tools">
          <div class="toggle-menu" v-if="callStatus.setting">
            <div class="item" @click="toggleCamera">
              <img src="../../../../assets/img/toggle-camera.png"/>
            </div>
            <div class="item" @click="toggleMic">
              <img src="../../../../assets/img/toggle-mic.png"/>
            </div>
          </div>
          <div class="toggle" @click="toggleSetting">
            <img v-if="!callStatus.setting" src="../../../../assets/img/setting-off.png"/>
            <img v-else src="../../../../assets/img/setting.png"/>
          </div>
        </div>
        <div class="room-introduce">
          <div class="toggle-icon" @click="toggleIntroduce">
            <img src="../../../../assets/img/info.png"/>
          </div>
        </div>
        <div class="message-panel">
          <div class="msg-list">
            <div class="item" v-for="(item,index) in messageList" :key="index" :index="index">
              <div class="system-msg" v-if="item.type === 'GRP_TIPS'">
                <span class="name">系统消息：</span>
                <span class="msg">{{ item.msg}}</span>
              </div>
              <div class="user-msg" v-else>
                <span class="name">{{ item.from_nick ? item.from_nick : item.from_user }} ：</span>
                <span class="msg">{{ item.msg }}</span>
              </div>
            </div>
          </div>
          <div class="send-panel">
            <van-field v-model="sendMsg" placeholder="">
              <template #button>
                <van-button size="small" type="primary" @click="sendGroupMsg">发送</van-button>
              </template>
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择摄像头弹窗-->
    <van-action-sheet description="摄像头列表" v-model="pickerCameraShow" :name="deviceList.cameraList.label" :actions="deviceList.cameraList" close-on-click-action cancel-text="取消" @select="onCameraSelect" />
    <!-- 选择麦克风弹窗-->
    <van-action-sheet description="麦克风列表" v-model="pickerMicShow" :name="deviceList.micList.label" :actions="deviceList.micList" close-on-click-action cancel-text="取消" @select="onMicSelect" />
    <!--房间简介弹窗-->
    <van-popup class="introduce-model" v-model="introduceShow" round position="bottom" :style="{ height: 'auto' }" >
      {{ roomInfo.room_introduce }}
    </van-popup>
    <!-- 需要申请才能访问弹窗-->
    <van-dialog v-model="applyModel" title="提示" show-cancel-button :before-close="coloseApplyDialog" confirm-button-text="申请">
      <div class="dialog-msg">该房间需要申请才能访问</div>
    </van-dialog>
    <!--登录弹窗-->
    <van-popup class="login-model" :close-on-click-overlay="false" v-model="LoginModel" round position="bottom" :style="{ height: 'auto' }">
      <div class="panel-title">用户未登录</div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="loginFrom.username"
          name="username"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="loginFrom.password"
          type="password"
          name="password"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div class="more-info">
          <div class="item" @click="toFindPassword">忘记密码？</div>
          <div class="item" @click="toRegister">注册账号</div>
        </div>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFFFFF" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </van-popup>
    <!--注册弹窗-->
    <van-popup class="register-model" :close-on-click-overlay="false" v-model="RegisterModel" round position="bottom" :style="{ height: 'auto' }">
      <div class="from-area">
        <van-form @submit="onRegisterSubmit">
          <van-field v-model="registerFrom.username" name="username" label="用户名" placeholder="用户名"
                     :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field v-model="registerFrom.password" type="password" name="password" label="密码" placeholder="密码"
                     :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field v-model="registerFrom.phone" type="text" name="phone" label="手机号" placeholder="手机号"
                     :rules="[{ required: true, message: '请填写手机号' }]"
          />
          <van-field v-model="registerFrom.code" center clearable name="code" label="短信验证码" placeholder="请输入短信验证码"
                     :rules="[{ required: true, message: '请填写短信验证码' }]"
          >
            <template #button>
              <van-button class="send-sms" native-type="button" :disabled="!codeInfo.countdown" v-bind:class="[codeInfo.countdown ? 'reg_active' : 'reg_disActive']" size="small" type="primary" color="#facc31" @click="sendRegisterCode">
                <span v-if="codeInfo.countdown">{{codeInfo.text}}</span>
                <span v-if="!codeInfo.countdown">({{codeInfo.count}}) s</span>
              </van-button>
            </template>
          </van-field>
          <van-field v-model="registerFrom.nick" name="nick" label="昵称" placeholder="昵称"
                     :rules="[{ required: true, message: '请填写昵称' }]"
          />
          <van-field name="avatar" label="头像">
            <template #input>
              <van-uploader :max-count="1" class="edit-arvat" v-model="registerFrom.avatar" :after-read="registerUpload"></van-uploader>
            </template>
          </van-field>
          <div class="more-info">
            <div class="item" @click="toLogin">已有账号？</div>
          </div>
          <div style="margin: 16px;" class="submit-btn">
            <van-button round block type="info" color="#ffffff" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <!-- 找回密码 -->
    <van-popup class="findpd-model" :close-on-click-overlay="false" v-model="FindPdModel" round position="bottom" :style="{ height: 'auto' }">
      <div class="from-area">
        <van-form @submit="onFindPdSubmit">
          <van-field v-model="findFrom.username" name="username" label="用户名" placeholder="用户名"
                     :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field v-model="findFrom.password" type="password" name="password" label="新密码" placeholder="新密码"
                     :rules="[{ required: true, message: '请填写新密码' }]"
          />
          <van-field v-model="findFrom.repassword" type="password" name="repassword" label="重复新密码" placeholder="重复新密码"
                     :rules="[{ required: true, message: '请填写重复新密码' }]"
          />
          <van-field v-model="findFrom.phone" type="text" name="phone" label="手机号" placeholder="手机号"
                     :rules="[{ required: true, message: '请填写手机号' }]"
          />
          <van-field v-model="findFrom.code" center clearable name="code" label="短信验证码" placeholder="请输入短信验证码"
                     :rules="[{ required: true, message: '请填写短信验证码' }]"
          >
            <template #button>
              <van-button native-type="button" :disabled="!codeInfo.countdown" v-bind:class="[codeInfo.countdown ? 'reg_active' : 'reg_disActive']" size="small" type="primary" color="#facc31" @click="sendFindePdCode">
                <span class="find-send-sms" v-if="codeInfo.countdown">{{codeInfo.text}}</span>
                <span v-if="!codeInfo.countdown">{{ codeInfo.text}} ({{codeInfo.count}})</span>
              </van-button>
            </template>
          </van-field>
          <div class="more-info">
            <div class="item" @click="toLogin">去登录？</div>
          </div>
          <div style="margin: 16px;" class="submit-btn">
            <van-button round block type="info" color="#ffffff" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <!-- 用户列表 -->
    <van-popup class="userList-model" v-model="userListShow" round position="bottom" :style="{ height: '40%' }" >
      <div class="user-list">
        <div class="title">用户列表</div>
        <div class="content">
          <div class="item" v-for="(item, index) in userList" :key="index" :index="index">
            <div class="user-avatar">
              <img :src="item.avatar"/>
            </div>
            <div class="user-name">
              {{ item.nick }} ( {{ item.username }} )
            </div>
            <div class="more-info" v-if="!item.ismy">
              <!-- 是好友 -->
              <div class="tool-item" v-if="item.isfriend">
                <van-icon name="passed" size="24"/>
              </div>
              <!-- 不是好友 -->
              <div class="tool-item" v-else>
                <van-icon name="add-o" size="24"  @click="addFriends(item.id)"/>
              </div>
            </div>
            <div class="more-info" v-else>
              <div class="tool-item">
                自己
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!--复制外链按钮-->
    <div class="clipboard" v-clipboard:copy="outLink(this.$route.params.unique)" v-clipboard:success="onCopy" v-clipboard:error="onError">
      <span>复制链接</span>
    </div>
    <!--显示房间分辨率-->
    <div class="room-definition">
      <div class="text">
        <span>当前分辨率: </span>
        <span v-if="roomInfo.definition === 0">480P</span>
        <span v-else-if="roomInfo.definition === 1">720P</span>
        <span v-else-if="roomInfo.definition === 2">1080P</span>
        <span v-else-if="roomInfo.definition === 3">1440P</span>
        <span v-else-if="roomInfo.definition === 4">4k高清</span>
        <span v-else>获取失败</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleMeeting',
  data () {
    return {
      checkResult: true,
      userListShow: false, // 用户列表是否显示
      userList: [], // 在线用户列表数据(包含好友列表)
      FindPdModel: false,
      findFrom: {
        username: '',
        password: '',
        repassword: '',
        phone: '',
        code: ''
      },
      RegisterModel: false,
      registerFrom: {
        username: '',
        password: '',
        phone: '',
        code: '',
        nick: '',
        avatar: []
      },
      codeInfo: {
        countdown: true,
        count: '',
        timer: null,
        text: '发送验证码'
      },
      LoginModel: false,
      loginFrom: {
        username: '',
        password: ''
      },
      count: 0,
      sendMsg: '',
      messageList: [],
      websock: null,
      wsClientId: '',
      roomInfo: {
        room_title: '',
        room_introduce: '',
        room_notice: '',
        max_person: '',
        apply: 1,
        owner: '',
        expire_time: '',
        room_status: '',
        definition: 0,
        unique: '',
        owner_user: '' // 房主用户名
      },
      legal: false, // 访问是否合法，合法才继续初始化SDK
      applyModel: false,
      // TRTC
      localStream: {},
      remoteStream: {},
      remoteShow: false,
      callStatus: {
        mic: true, // mic图标状态
        camera: true, // camera图标状态
        setting: false // 设置图标状态
      },
      deviceList: {
        micList: [],
        activeMic: '',
        cameraList: [],
        activeCamera: ''
      },
      pickerCameraShow: false, // 选择摄像头弹窗
      pickerMicShow: false, // 选择麦克风弹窗
      introduceShow: false // 房间介绍弹窗
    }
  },
  created () {
    this.get_room_info()
  },
  methods: {
    async addFriends (id) {
      const { data: res } = await this.$http.post('/index/index-add-frineds', { friend_id: id })
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        this.getUserListByGroup()
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    async pageInit () {
      if (this.$store.state.userInfo.username && this.$store.state.userInfo.token) {
        const { data: res } = await this.$http.post('/index/check-token', { token: this.$store.state.userInfo.token })
        if (res.meta.status === 200) {
          // 验证访问合法性
          this.checkInit()
          // TRTC 入口
          this.createClient()
          this.joinRoom()
          // 消息监听 WS
          this.wsInit()
        } else {
          this.$toast.fail('身份信息验证失败，请重新登录')
          // 跳转到登录页面
          this.LoginModel = true
        }
      } else {
        this.$toast.fail('您还未登录，请先登录')
        // 跳转到登录页面
        this.LoginModel = true
      }
    },
    wsInit () {
      this.websock = new WebSocket('wss://chat.ws.gljshz.com:8567')
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonerror () { // 连接建立失败重连
      this.wsInit()
    },
    async websocketonmessage (e) { // 数据接收
      const data = JSON.parse(e.data)
      const that = this
      // 如果刷新页面 client_id 会变动，需要重新获取client_id
      if (data.type === 'init') {
        that.$store.commit('updateClientId', data.client_id)
        // 绑定uid 到 client_id
        const { data: res } = await that.$http.post('/index/bind', { client_id: that.$store.state.wsInfo.client_id, username: window.sessionStorage.getItem('username'), token: window.sessionStorage.getItem('token') })
        if (res.meta.status === 200) {
          that.wsJoinRoom()
        } else {
          that.$toast.fail(res.meta.msg)
        }
      } else if (data.type === 'GRP_TIPS' || data.type === 'GRP_MSG' || data.type === 'INVITE_MSG') {
        that.count = data.count
        that.messageList.push(data)
      }
    },
    // ws加入房间
    async wsJoinRoom () {
      // 如果vuex中有client_id则请求加入群组，如果不满足则通过 websocketonmessage 的 init 重新绑定到uid再加入群组
      if (this.$store.state.wsInfo.client_id) {
        await this.$http.post('/index/ws-join-group', { client_id: this.$store.state.wsInfo.client_id, group_id: this.$route.params.unique })
      }
    },
    // WS发送群组消息
    async sendGroupMsg () {
      if (this.sendMsg) {
        await this.$http.post('/index/send-group-msg', { group_id: this.$route.params.unique, msg: this.sendMsg })
        this.sendMsg = ''
      } else {
        this.$toast.fail('请输入文字')
      }
    },
    // WS退出房间
    async wsLeaveGroup () {
      const { data: res } = await this.$http.post('/index/ws-leave-group', { client_id: this.$store.state.wsInfo.client_id, group_id: this.$route.params.unique })
      if (res.meta.status === 200) {
        window.location.href = 'about:blank'
        window.close()
      }
    },
    async getUserListByGroup () {
      const { data: res } = await this.$http.get('/index/get-user-list-by-group/' + this.$route.params.unique)
      if (res.meta.status === 200) {
        this.userList = res.data.userList
        this.userListShow = true
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 如果不需要登录则获取随机账号
    async getAccout () {
      const { data: res } = await this.$http.get('/index/get-free-account')
      if (res.meta.status === 200) {
        this.$store.commit('login', res.data)
        window.sessionStorage.setItem('username', res.data.username)
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('SDKAppID', res.data.SDKAppID)
        this.pageInit()
      }
    },
    async get_room_info () {
      const { data: res } = await this.$http.get('/index/get-single-meeting', { params: { unique: this.$route.params.unique } })
      if (res.meta.status === 200) {
        this.roomInfo.room_title = res.data.room_title
        this.roomInfo.room_introduce = res.data.room_introduce
        this.roomInfo.room_notice = res.data.room_notice
        this.roomInfo.max_person = res.data.max_person
        this.roomInfo.apply = res.data.apply
        this.roomInfo.owner = res.data.owner
        this.roomInfo.expire_time = res.data.expire_time
        this.roomInfo.room_status = res.data.room_status
        this.roomInfo.definition = res.data.definition
        this.roomInfo.unique = res.data.unique
        this.roomInfo.owner_user = res.data.owner_user
        if (res.data.apply === 1) {
          this.getAccout()
        } else {
          this.pageInit()
        }
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 进房前的各种权限检查
    checkInit () {
      this.checkAuth()
      this.checkOverTime()
      this.checkRoomStatus()
    },
    // 检查会议室是否已满
    checkMaxNum () {
      //  如果是房主则跳过检查
      console.log('检查会议室是否已满')
    },
    // 根据会议室的验证方式显示是否需要申请才能访问
    checkAuth () {
      //  如果是房主则跳过检查
      // console.log('检查是否需要申请才能访问此房间')
      if (this.roomInfo.apply === 2 && this.$store.state.userInfo.username !== this.roomInfo.owner_user) {
        this.applyModel = true
      }
    },
    // 检查房间是否已过期
    checkOverTime () {
      // console.log('检查房间是否已过期')
      const expiredate = this.roomInfo.expire_time * 1000
      console.log(Date.now())
      if (expiredate !== 0 && Date.now() > expiredate) {
        this.$toast.fail('房间已过期，请联系房主更续时间')
        this.checkResult = false
      }
    },
    // 检查房间状态是否正常
    checkRoomStatus () {
      // console.log('检查房间状态是否正常')
      if (this.roomInfo.room_status === 1) {
        this.$toast.fail('当前房间正在审核中...')
        this.checkResult = false
      } else if (this.roomInfo.room_status === 3) {
        this.$toast.fail('当前房间未审核通过，禁止访问...')
        this.checkResult = false
      }
    },
    // 关闭申请弹窗函数
    async coloseApplyDialog (action, done) {
      if (action === 'confirm') {
        const { data: res } = await this.$http.post('/index/apply-join-room', { unique: this.$route.params.unique })
        if (res.meta.status === 200) {
          this.$toast.success(res.meta.msg)
        } else {
          this.$toast.fail(res.meta.msg)
        }
        done()
        this.$router.push('/user/meeting-home/meeting-enter/' + this.$route.params.unique)
      } else {
        done()
        this.$router.push('/user/meeting-home/meeting-enter/' + this.$route.params.unique)
      }
    },
    // TRTC相关
    // 切换摄像头
    toggleCamera () {
      this.$TRTC.getCameras().then(devices => {
        this.deviceList.cameraList = []
        devices.forEach(dev => {
          this.deviceList.cameraList.push({ name: dev.label, subname: dev.deviceId })
        })
        this.pickerCameraShow = true
      })
    },
    onCameraSelect (item) {
      this.localStream.switchDevice('video', item.subname).then(() => {
        this.$toast.success('切换摄像头：' + item.name + ' 成功！')
      })
    },
    // 切换麦克风
    toggleMic () {
      this.$TRTC.getMicrophones().then(devices => {
        this.deviceList.micList = []
        devices.forEach(dev => {
          this.deviceList.micList.push({ name: dev.label, subname: dev.deviceId })
        })
        this.pickerMicShow = true
      })
    },
    onMicSelect (item) {
      this.localStream.switchDevice('audio', item.subname).then(() => {
        this.$toast.success('切换麦克风：' + item.name + ' 成功！')
      })
    },
    // 退出房间
    leaveRoom () {
      const that = this
      // 取消发布本地流
      this.client.unpublish(this.localStream).then(() => {
        // 取消发布本地流成功
        that.client.leave().then(() => {
          that.$toast.success('退出房间成功！，请稍等')
          this.$store.commit('logout')
          // ws 退出房间
          that.wsLeaveGroup()
          // leaving room success
        }).catch(error => {
          that.$toast.fail('退出房间失败！' + error)
        })
      }).catch(function (error) {
        // 处理 getJSON 和 前一个回调函数运行时发生的错误
        console.log('发生错误！', error)
        // leaving room success
        that.$toast.success('退出房间成功！，请稍等')
        that.wsLeaveGroup()
      })
    },
    // 开启关闭麦克风
    trunMic () {
      if (this.callStatus.mic) {
        // 关闭音频，远端会收到 ‘mute-audio’ 事件
        this.localStream.muteAudio()
        this.$toast.success('已关闭麦克风')
        this.callStatus.mic = false
      } else {
        // 启用音频，远端会收到 ‘unmute-audio’ 事件
        this.localStream.unmuteAudio()
        this.$toast.success('已开启麦克风')
        this.callStatus.mic = true
      }
    },
    // 开启关闭摄像头
    trunCamera () {
      if (this.callStatus.camera) {
        // 关闭视频，远端会收到 ‘mute-video’ 事件
        this.localStream.muteVideo()
        this.$toast.success('已关闭摄像头')
        this.callStatus.camera = false
      } else {
        // 启用视频，远端会收到 ‘unmute-video’ 事件
        this.localStream.unmuteVideo()
        this.$toast.success('已开启摄像头')
        this.callStatus.camera = true
      }
    },
    toggleSetting () {
      this.callStatus.setting = !this.callStatus.setting
    },
    // 创建 Client 对象
    createClient () {
      this.client = this.$TRTC.createClient({
        mode: 'rtc',
        sdkAppId: this.$store.state.userInfo.appid,
        userId: this.$store.state.userInfo.username,
        userSig: this.$store.state.userInfo.token,
        useStringRoomId: true
      })
      this.$TRTC.Logger.setLogLevel(this.$TRTC.Logger.LogLevel.ERROR)
    },
    //  进入房间
    joinRoom () {
      this.client.join({ roomId: this.$route.params.unique })
        .catch(error => {
          console.error('进房失败 ' + error)
        })
        .then(() => {
          console.log('进房成功')
          this.subscribe()
          this.createStream()
        })
    },
    // 创建本地音视频流 并初始化
    createStream () {
      this.localStream = this.$TRTC.createStream({ userId: this.$store.state.userInfo.username, audio: true, video: true })
      if (this.roomInfo.definition === 0) {
        this.localStream.setVideoProfile({ width: 640, height: 480, frameRate: 15, bitrate: 900 })
      } else if (this.roomInfo.definition === 1) {
        this.localStream.setVideoProfile({ width: 1280, height: 720, frameRate: 15, bitrate: 1500 })
      } else if (this.roomInfo.definition === 2) {
        this.localStream.setVideoProfile({ width: 1920, height: 1080, frameRate: 30, bitrate: 2000 })
      } else if (this.roomInfo.definition === 3) {
        this.localStream.setVideoProfile({ width: 2560, height: 1440, frameRate: 30, bitrate: 4860 })
      } else if (this.roomInfo.definition === 4) {
        this.localStream.setVideoProfile({ width: 3840, height: 2160, frameRate: 30, bitrate: 9000 })
      }
      this.localStream
        .initialize()
        .catch(error => {
          console.error('初始化本地流失败 ' + error)
        })
        .then(() => {
          console.log('初始化本地流成功')
          // 发布本地流
          this.publish()
        })
    },
    // 发布本地音视频流
    publish () {
      this.client
        .publish(this.localStream)
        .catch(error => {
          console.error('本地流发布失败 ' + error)
        })
        .then(() => {
          console.log('本地流发布成功')
          this.localStream.objectFit = 'contain'
          this.localStream.play('local_stream', { objectFit: 'contain' })
          document.getElementById('player_' + this.localStream.getId()).style.cssText = 'height: 200px;width: 100%;background-color: #d4e0ea;'
          document.getElementById('player_' + this.localStream.getId()).setAttribute('webkit-playsinline', 'webkit-playsinline')
          document.getElementById('player_' + this.localStream.getId()).onclick = function () {
            if (this.className.indexOf('max-view') !== -1) {
              this.classList.remove('max-view')
            } else {
              this.classList.add('max-view')
            }
          }
          // 获取实际采集的帧率/分辨率信息
          const videoTrack = this.localStream.getVideoTrack()
          if (videoTrack) {
            const settings = videoTrack.getSettings()
            console.log(`当前摄像头信息：分辨率：${settings.width} * ${settings.height}, 帧率：${settings.frameRate}`)
          } else {
            console.log(videoTrack)
          }
        })
    },
    // 订阅远端音视频流
    subscribe () {
      console.log('订阅远端音视频流')
      this.remoteShow = true
      this.client.on('stream-added', event => {
        const remoteStream = event.stream
        this.remoteStream = remoteStream
        console.log('远端流增加: ' + remoteStream.getId())
        // 订阅远端流
        this.client.subscribe(remoteStream)
      })
      this.client.on('stream-subscribed', event => {
        const remoteStream = event.stream
        this.remoteStream = remoteStream
        console.log('远端流订阅成功：' + remoteStream.getId())
        // 播放远端流
        remoteStream.play('remote_stream', { objectFit: 'contain' })
        document.getElementById('player_' + remoteStream.getId()).style.cssText = 'height: 100px;width: 23%;background-color: #d4e0ea;margin: 1%;'
        document.getElementById('player_' + remoteStream.getId()).setAttribute('webkit-playsinline', 'webkit-playsinline')
        document.getElementById('player_' + remoteStream.getId()).onclick = function () {
          if (this.className.indexOf('max-view') !== -1) {
            this.classList.remove('max-view')
          } else {
            this.classList.add('max-view')
          }
        }
      })
    },
    toggleIntroduce () {
      this.introduceShow = true
    },
    /**
     * 登录注册相关
     */
    // 登录提交表单按钮
    async onSubmit (values) {
      const { data: res } = await this.$http.post('/index/login', values)
      if (res.meta.status === 200) {
        this.$store.commit('login', res.data)
        this.$notify({ type: 'success', message: '登录成功' })
        window.sessionStorage.setItem('username', res.data.username)
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('SDKAppID', res.data.SDKAppID)
        this.pageInit()
        this.LoginModel = false
        this.$forceUpdate()
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    toFindPassword () {
      this.LoginModel = false
      this.RegisterModel = false
      this.FindPdModel = true
    },
    toRegister () {
      this.LoginModel = false
      this.FindPdModel = false
      this.RegisterModel = true
    },
    toLogin () {
      this.RegisterModel = false
      this.FindPdModel = false
      this.LoginModel = true
    },
    /**
     * 注册相关
     */
    async onRegisterSubmit (values) {
      const { data: res } = await this.$http.post('/index/register', values)
      if (res.meta.status === 200) {
        this.$notify({ type: 'success', message: '注册成功' })
        this.$forceUpdate()
        this.LoginModel = true
        this.RegisterModel = false
        this.FindPdModel = false
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
      }
    },
    async registerUpload (file) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.registerFrom.avatar[0].url = res.data
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    },
    // 发送验证码
    async sendRegisterCode () {
      if (!this.registerFrom.phone) {
        this.$toast.fail('请先填写手机号')
        return false
      }
      const TIME_COUNT = 60
      const { data: res } = await this.$http.post('/index/send-code', { phone: this.registerFrom.phone })
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        if (!this.codeInfo.timer) {
          this.codeInfo.count = TIME_COUNT
          this.codeInfo.countdown = false
          this.codeInfo.text = '重新发送'
          this.codeInfo.timer = setInterval(() => {
            if (this.codeInfo.count > 0 && this.codeInfo.count <= TIME_COUNT) {
              this.codeInfo.count--
            } else {
              this.codeInfo.countdown = true

              clearInterval(this.codeInfo.timer)
              this.codeInfo.timer = null
            }
          }, 1000)
        }
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    /**
     * 找回密码相关
     */
    async onFindPdSubmit (values) {
      if (values.password !== values.repassword) {
        this.$toast.fail('两次密码输入不一致')
        return false
      }
      const { data: res } = await this.$http.post('/index/find-password', values)
      if (res.meta.status === 200) {
        this.$notify({ type: 'success', message: '密码重置成功' })
        this.$forceUpdate()
        this.LoginModel = true
        this.RegisterModel = false
        this.FindPdModel = false
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
      }
    },
    async sendFindePdCode () {
      if (!this.findFrom.phone) {
        this.$toast.fail('请先填写手机号')
        return false
      }
      const TIME_COUNT = 60
      const { data: res } = await this.$http.post('/index/send-code', { phone: this.findFrom.phone })
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        if (!this.codeInfo.timer) {
          this.codeInfo.count = TIME_COUNT
          this.codeInfo.countdown = false
          this.codeInfo.text = '重新发送'
          this.codeInfo.timer = setInterval(() => {
            if (this.codeInfo.count > 0 && this.codeInfo.count <= TIME_COUNT) {
              this.codeInfo.count--
            } else {
              this.codeInfo.countdown = true

              clearInterval(this.codeInfo.timer)
              this.codeInfo.timer = null
            }
          }, 1000)
        }
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 复制外部链接功能
    onCopy: function (e) {
      this.$toast.success('复制成功')
    },
    onError: function (e) {
      this.$toast.fail('复制失败')
    },
    outLink (unique) {
      return 'https://chat.gljshz.com/#/single-meeting/' + unique
    }
  },
  updated () {
    this.$nextTick(() => {
      var container = this.$el.querySelector('.msg-list')
      container.scrollTop = container.scrollHeight
    })
  }
}
</script>

<style scoped>
.room-definition{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #ed6a0c;
  text-align: center;
}
.room-definition .text{
  background-color: #fffbe8;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
}
/deep/.max-view{
  background-color: rgb(212, 224, 234)!important;
  position: fixed!important;
  top: 0!important;
  left: 0!important;
  width: 100% !important;
  height: 100% !important;
  z-index: 100!important;
}
/deep/.max-view video{
  transform: none!important;
}
#remote_stream{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 220px;
  overflow-y: scroll;
}
.meeting{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #F1F6FA;
  height: 100vh;
}
.main-tabbar{
  display: none;
}
.dialog-msg{
  padding: 30px 10px;
  text-align: center;
}
#local_stream{
  width: 100%;
  height: 200px;
}
.menu-float{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.menu-float .header{
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  pointer-events: auto;
}
.menu-float .header .left{}
.menu-float .header .left img{
  width: 40px;
  height: 40px;
}
.menu-float .header .middle{
  font-size: 16px;
  font-weight: bold;
}
.menu-float .header .right{}
.menu-float .header .right .users-num{
  font-size: 14px;
  background-color: rgba(255,255,255,0.8);
  padding: 4px 10px;
  border-radius: 30px;
}
.menu-float .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0px 20px 40px 20px;
  pointer-events: auto;
}
.menu-float .footer .main-icon{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
}
.menu-float .footer .main-icon .item{}
.menu-float .footer .main-icon .item img{
  width: 40px;
}
.menu-float .footer .main-icon .item:nth-child(2) img{
  width: 50px;
}
.menu-float .footer .tools{
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: 100;
}
.menu-float .footer .tools .toggle{}
.menu-float .footer .tools .toggle img{
  width: 30px;
}
.menu-float .footer .tools .toggle-menu{
  margin-bottom: 10px;
}
.menu-float .footer .tools .toggle-menu .item{
  margin: 5px 0;
}
.menu-float .footer .tools .toggle-menu .item img{
  width: 30px;
}
.room-introduce{
  position: absolute;
  bottom: 100px;
  left: 20px;
}
.room-introduce .toggle-icon{}
.room-introduce .toggle-icon img{
  width: 30px;
}
.introduce-model{
  padding: 60px 30px;
  font-size: 14px;
}
.message-panel{
  position: absolute;
  bottom: 150px;
  left: 10%;
  width: 80%;
  box-sizing: border-box;
}
.message-panel .msg-list{
  max-height: 150px;
  overflow-y: scroll;
}
.message-panel .msg-list .item{
  font-size: 14px;
}
.message-panel .msg-list .item .system-msg{
  color: #facc31;
}
.message-panel .msg-list .item .user-msg{}
.message-panel .msg-list .item .user-msg .name{
  color: #07c160;
}
.message-panel .msg-list .item .msg{
  max-width: 100%;
  word-wrap: break-word;
  word-break: normal;
}
.message-panel .send-panel{
  margin-top: 6px;
}
.message-panel .van-cell {
  padding: 4px 6px;
  border-radius: 4px;
  background-color: rgba(255,255,255,0.8);
}
.login-model{
  padding: 20px 20px 20px 20px;
  text-align: center;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 100%);
  color: white;
  width: 100%;
}
.login-model .panel-title{
  font-size: 30px;
  opacity: 0.4;
  padding: 40px 0;
}
.login-model .more-info{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.login-model .more-info .item{
  color: #fbfbfb;
  font-size: 14px;
  margin-left: 16px;
}
/deep/.login-model .van-button__text{
  color: #FFBB12;
}
/deep/.login-model .van-cell{
  margin: 10px 0;
  border-radius: 4px;
}
.register-model{
  padding: 20px;
  text-align: center;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 100%);
  color: white;
  width: 100%;
  border-radius: 6px;
}
.register-model .more-info{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.register-model .more-info .item{
  color: #fbfbfb;
  font-size: 14px;
  margin-left: 16px;
}
/deep/.register-model .van-button__text{
  color: #FFBB12;
}
/deep/.register-model .van-cell{
  margin: 10px 0;
  border-radius: 4px;
}
/deep/.register-model .send-sms .van-button__text{
  color: #FFFFFF;
}
.findpd-model{
  padding: 20px;
  text-align: center;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 100%);
  color: white;
  width: 100%;
  border-radius: 6px;
}
.findpd-model .more-info{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.findpd-model .more-info .item{
  color: #fbfbfb;
  font-size: 14px;
  margin-left: 16px;
}
/deep/.findpd-model .van-button__text{
  color: #FFBB12;
}
/deep/.findpd-model .van-cell{
  margin: 10px 0;
  border-radius: 4px;
}
.find-send-sms{
  color: #FFFFFF;
}
.userList-model{}
.userList-model .user-list{}
.userList-model .user-list .title{
  width: 100%;
  text-align: center;
  padding: 10px 8px;
}
.userList-model .user-list .content{}
.userList-model .user-list .content .item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px 4px 4px;
  background-color: #F1F6FA;
  margin: 10px;
  border-radius: 40px;
}
.userList-model .user-list .content .item .user-avatar{
  width: 40px;
  height: 40px;
}
.userList-model .user-list .content .item .user-avatar img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.userList-model .user-list .content .item .user-name{
  flex: 1;
  margin-left: 12px;
  font-size: 14px;
}
.userList-model .user-list .content .item .more-info{
  display: flex;
  align-items: center;
  justify-content: center;
}
.userList-model .user-list .content .item .more-info .tool-item{
  margin: 0 4px;
}
.video-list{
  margin-top: 120px;
}
.clipboard{
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: bt-rl;
  writing-mode: vertical-rl;
  text-align: center;
  padding: 6px;
  border-radius: 6px 0 0 6px;
  font-size: 14px;
  color: #FFFFFF;
  text-indent: 1px;
  background-color: #facc31;
  position: fixed;
  right: 0;
  top: 60%;
}
</style>
