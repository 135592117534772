<template>
  <div class="main-tabbar">
    <van-tabbar v-model="activeTabbar" :border="false" active-color="#facc31" inactive-color="#919191">
      <van-tabbar-item replace to="/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/classify">
        <span>分类</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/message" :badge="messageNum">
        <span>消息</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/user">
        <span>用户</span>
        <template #icon="props">
          <img :src="props.active ? icon4.active : icon4.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'MainTabBar',
  data () {
    return {
      activeTabbar: this.activeMenu,
      icon1: {
        active: require('../../../assets/img/icon1-active.png'),
        inactive: require('../../../assets/img/icon1.png')
      },
      icon2: {
        active: require('../../../assets/img/icon2-active.png'),
        inactive: require('../../../assets/img/icon2.png')
      },
      icon3: {
        active: require('../../../assets/img/icon3-active.png'),
        inactive: require('../../../assets/img/icon3.png')
      },
      icon4: {
        active: require('../../../assets/img/icon4-active.png'),
        inactive: require('../../../assets/img/icon4.png')
      },
      messageNum: this.$store.state.messageList.length
    }
  },
  computed: {
    getNums () {
      return this.$store.state.messageList.length
    }
  },
  watch: {
    getNums (val) {
      this.messageNum = val
    }
  }
}
</script>

<style scoped>

</style>
