<template>
  <div class="user-main">
    <user-page></user-page>
  </div>
</template>

<script>
import UserLogined from './widgets/UserLogined'
export default {
  name: 'UserMain',
  components: {
    'user-page': UserLogined
  },
  data () {
    return {}
  },
  created () {
    this.checkLogin()
  },
  methods: {
    checkLogin () {
      // 检查用户登录状态
      if (!this.$store.state.userInfo.username || !this.$store.state.userInfo.token) {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>

</style>
