<template>
  <div class="reset-password">
    <van-nav-bar :fixed="true" :placeholder="true" title="密码找回" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="bg-img"></div>
    <div class="wave">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
        </g>
      </svg>
    </div>
    <div class="from-area">
      <van-form @submit="onSubmit">
        <van-field v-model="findFrom.username" name="username" label="用户名" placeholder="用户名"
                   :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field v-model="findFrom.password" type="password" name="password" label="新密码" placeholder="新密码"
                   :rules="[{ required: true, message: '请填写新密码' }]"
        />
        <van-field v-model="findFrom.repassword" type="password" name="repassword" label="重复新密码" placeholder="重复新密码"
                   :rules="[{ required: true, message: '请填写重复新密码' }]"
        />
        <van-field v-model="findFrom.phone" type="text" name="phone" label="手机号" placeholder="手机号"
                   :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field v-model="findFrom.code" center clearable name="code" label="短信验证码" placeholder="请输入短信验证码"
                   :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button native-type="button" :disabled="!codeInfo.countdown" v-bind:class="[codeInfo.countdown ? 'reg_active' : 'reg_disActive']" size="small" type="primary" color="#facc31" @click="sendCode">
              <span v-if="codeInfo.countdown">{{codeInfo.text}}</span>
              <span v-if="!codeInfo.countdown">{{ codeInfo.text}} ({{codeInfo.count}})</span>
            </van-button>
          </template>
        </van-field>
        <div style="margin: 16px;" class="submit-btn">
          <van-button round block type="info" color="#ffffff" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestPd',
  data () {
    return {
      findFrom: {
        username: '',
        password: '',
        repassword: '',
        phone: '',
        code: ''
      },
      codeInfo: {
        countdown: true,
        count: '',
        timer: null,
        text: '发送验证码'
      }
    }
  },
  created () {
  },
  methods: {
    onClickLeft () {
      this.$router.push('/user')
    },
    async onSubmit (values) {
      if (values.password !== values.repassword) {
        this.$toast.fail('两次密码输入不一致')
        return false
      }
      const { data: res } = await this.$http.post('/index/find-password', values)
      if (res.meta.status === 200) {
        this.$notify({ type: 'success', message: '密码重置成功' })
        this.$forceUpdate()
        this.$router.push('/user')
      } else {
        this.$notify({ type: 'danger', message: res.meta.msg })
      }
    },
    // 发送验证码
    async sendCode () {
      if (!this.findFrom.phone) {
        this.$toast.fail('请先填写手机号')
        return false
      }
      const TIME_COUNT = 60
      const { data: res } = await this.$http.post('/index/send-code', { phone: this.findFrom.phone })
      if (res.meta.status === 200) {
        this.$toast.success(res.meta.msg)
        if (!this.codeInfo.timer) {
          this.codeInfo.count = TIME_COUNT
          this.codeInfo.countdown = false
          this.codeInfo.text = '重新发送'
          this.codeInfo.timer = setInterval(() => {
            if (this.codeInfo.count > 0 && this.codeInfo.count <= TIME_COUNT) {
              this.codeInfo.count--
            } else {
              this.codeInfo.countdown = true

              clearInterval(this.codeInfo.timer)
              this.codeInfo.timer = null
            }
          }, 1000)
        }
      } else {
        this.$toast.fail(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
/deep/.submit-btn .van-button__text{
  color: #FFBB12;
}
.from-area{
  padding: 30px;
  border-radius: 6px;
}
/deep/.van-cell{
  margin: 10px 0;
  border-radius: 4px;
}
.reset-password{
  position: relative;
  text-align: center;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 100%);
  color: white;
  width: 100%;
  height: calc(100vh - 46px);
}
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.wave{
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
.waves {
  height:40px;
  min-height:40px;
  width: 100%;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform:rotate(200deg);
  }
  100%{
    transform: rotate(0);
  }
}

.rotate{
  transition: 0.5s;
  animation: rotate 10s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}
</style>
