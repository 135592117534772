<template>
  <div class="friends">
    <van-nav-bar :fixed="true" :placeholder="true" title="好友管理" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="friends-list">
      <div class="item" @click="toChat(item.id)" v-for="(item,index) in friendsList" :key="index" :index="index">
        <div class="left">
          <div class="avatar" :style="{ 'backgroundImage':'url('+ item.avatar +')' }"></div>
        </div>
        <div class="right">
          <div class="name-status">
            <div class="name">{{ item.nick }}</div>
            <div class="status online" v-if="item.status === 'online'"></div>
            <div class="status offline" v-else></div>
          </div>
          <div class="message">
            {{ item.username }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FriendsList',
  data () {
    return {
      friendsList: []
    }
  },
  created () {
    this.getFriendList()
  },
  methods: {
    // 获取好友列表
    async getFriendList () {
      const { data: res } = await this.$http.get('/index/user-get-friends-list')
      if (res.meta.status === 200) {
        this.friendsList = res.data
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    onClickLeft () {
      this.$router.push('/user')
    },
    // 点击好友，跳转到聊天页面
    toChat (id) {
      this.$router.push('/user/friends-chat/' + id)
    }
  }
}
</script>

<style scoped>
.friends{
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #F1F6FA;
}
/deep/.van-nav-bar{
  z-index: 100;
}
/deep/.van-nav-bar__text{
  color: #facc31;
}
/deep/.van-nav-bar .van-icon{
  color: #facc31;
}
.friends-list{
  padding: 10px 10px 60px 10px;
}
.friends-list .item{
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.friends-list .item .left{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.friends-list .item .left .avatar{
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 4px;
}
.friends-list .item .right{
  flex: 1;
}
.friends-list .item .right .name-status{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}
.friends-list .item .right .name-status .name{
  font-size: 15px;
  font-weight: bold;
}
.friends-list .item .right .name-status .status{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.friends-list .item .right .name-status .online{
  background-color: #07c160;
}
.friends-list .item .right .name-status .offline{
  background-color: #C8C9CC;
}
.friends-list .item .right .name-status .busy{
  background-color: #FAAB0C;
}
.friends-list .item .right .message{
  font-size: 14px;
  opacity: 0.6;
  margin-top: 4px;
}
</style>
