<template>
  <div class="has-login">
    <div class="information">
      <div class="bg-img"></div>
      <div class="wave">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
          </g>
        </svg>
      </div>
      <div class="user-info">
        <div class="avart">
          <img :src="profile.avatar[0].url">
        </div>
        <div class="nickname">
          {{ profile.nick }}
          <br>
          {{ profile.username }}
        </div>
        <div class="seeting-icon" @click="showPopup">
          <van-icon class="rotate" name="setting-o" />
        </div>
      </div>
    </div>
    <div class="user-menu">
      <div class="menu-item" @click="toRoomManage">
        <img src="../../../../assets/img/room.png"/>
        <span>会议管理</span>
      </div>
      <div class="menu-item" @click="toLiveRoom">
        <img src="../../../../assets/img/live-room.png"/>
        <span>直播间</span>
      </div>
    </div>
    <div class="page-content">
      <div class="menu-cell">
        <van-cell title="好友" is-link to="/user/friends" />
        <van-cell title="翻译" is-link to="/user/translate" />
      </div>
    </div>
    <div class="logout" @click="logout">退出登录</div>
    <!-- 设置用户资料 -->
    <van-popup class="edit-user-popup" v-model="show" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="onUserSubmit">
        <van-uploader class="edit-arvat" :after-read="afterUpload" multiple :max-count="2" style="text-align: center">
          <van-image round fit="cover" width="3rem" height="3rem" :src="profile.avatar[0] ? profile.avatar[0].url : 'https://img01.yzcdn.cn/vant/cat.jpeg'"/>
          <span>点击修改头像</span>
        </van-uploader>
        <van-field
          v-model="profile.nick"
          name="nick"
          label="用户昵称"
          placeholder="请输入昵称"
          :rules="[{ required: true, message: '请填写用户昵称' }]"
        />
        <van-field name="radio" label="性别">
          <template #input>
            <van-radio-group v-model="profile.gender" direction="horizontal">
              <van-radio checked-color="#FFBB12" :name="1">男</van-radio>
              <van-radio checked-color="#FFBB12" :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          label="生日"
          :value="profile.birthday"
          placeholder="选择生日时间"
          @click="birthdayPickerShow = true"
        />
        <van-field v-model="profile.selfSignature" rows="2" autosize label="个性签名" type="textarea" maxlength="50" placeholder="请输入个性签名" show-word-limit/>
        <van-field name="radio" label="好友验证">
          <template #input>
            <van-radio-group v-model="profile.allowType" direction="horizontal">
              <van-radio checked-color="#FFBB12" :name="1">无验证</van-radio>
              <van-radio checked-color="#FFBB12" :name="2">需要验证</van-radio>
              <van-radio checked-color="#FFBB12" :name="3">拒绝</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field readonly clickable name="area" :value="profile.location" label="地区选择" placeholder="点击选择省市区" @click="showArea = true"/>
        <van-popup v-model="showArea" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false"/>
        </van-popup>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 申请房间 -->
    <van-popup class="apply-room-popup" v-model="applyVisible" round position="bottom" :style="{ height: 'auto' }">
      <van-form @submit="onApplySubmit">
        <van-field
          v-model="applyRoom.name"
          name="name"
          label="房间名称"
          placeholder="请输入会议名称"
          :rules="[{ required: true, message: '请填写会议名称' }]"
        />
        <van-field v-model="applyRoom.introduction" rows="2" autosize label="房间简介" type="textarea" maxlength="240" placeholder="请输入房间简介" show-word-limit/>
        <van-field v-model="applyRoom.notification" rows="2" autosize label="房间公告" type="textarea" maxlength="300" placeholder="请输入房间公告" show-word-limit/>
        <van-field name="avatar" label="房间头像">
          <template #input>
            <van-uploader :max-count="1" class="edit-arvat" v-model="applyRoom.avatar" :after-read="applyUpload"></van-uploader>
          </template>
        </van-field>
        <van-field v-model="applyRoom.maxMemberNum" type="digit" label="最大参会人数" />
        <van-field name="radio" label="参加会议申请处理方式">
          <template #input>
            <van-radio-group v-model="applyRoom.joinOption" direction="horizontal">
              <van-radio checked-color="#FFBB12" :name="1">自由加入</van-radio>
              <van-radio checked-color="#FFBB12" :name="2">需要验证</van-radio>
              <van-radio checked-color="#FFBB12" :name="3">禁止加群</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#FFBB12" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 选择生日-->
    <van-popup v-model="birthdayPickerShow" round position="bottom">
      <van-datetime-picker @confirm="confirmBirthday" v-model="pickerDate.currentDate" type="date" title="选择年月日" :min-date="pickerDate.minDate" :max-date="pickerDate.maxDate"/>
    </van-popup>
  </div>
</template>

<script>
import Area from '../../../../assets/js/area'
export default {
  name: 'UserLogined',
  data () {
    return {
      applyRoom: {
        name: '',
        introduction: '',
        notification: '',
        avatar: [],
        maxMemberNum: 100,
        joinOption: 1
      },
      // 用户基础信息
      profile: {
        username: '',
        nick: '',
        avatar: [{ url: '' }],
        gender: '',
        phone: '',
        birthday: '',
        location: '',
        selfSignature: '',
        allowType: ''
      },
      show: false,
      showArea: false,
      areaList: Area, // 数据格式见 Area 组件文档
      applyVisible: false, // 申请房间弹窗表单是否可见
      birthdayPickerShow: false, // 选择生日弹窗是否可见
      pickerDate: {
        minDate: new Date(1900, 0, 1),
        maxDate: new Date(),
        currentDate: new Date()
      }
    }
  },
  created () {
    this.getUserProfile()
  },
  methods: {
    async getUserProfile () {
      const { data: res } = await this.$http.get('/index/get-user-info')
      if (res.meta.status === 200) {
        this.profile.username = res.data.username
        this.profile.nick = res.data.nick
        this.profile.avatar[0] = { url: res.data.avatar }
        this.profile.gender = res.data.gender
        this.profile.phone = res.data.phone
        this.profile.birthday = res.data.birthday
        this.profile.location = res.data.location
        this.profile.selfSignature = res.data.selfSignature
        this.profile.allowType = res.data.allowType
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    // 用户修改资料
    async onUserSubmit () {
      const { data: res } = await this.$http.post('/index/edit-profile', this.profile)
      if (res.meta.status === 200) {
        this.$toast.success('更新资料成功')
        this.show = false
      } else {
        this.$toast.fail(res.meta.msg)
      }
      console.log('用户修改资料')
    },
    // 用户申请房间
    onApplySubmit () {
      console.log('申请房间')
    },
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
    toRoomManage () {
      this.$router.push('/user/meeting-home')
    },
    toLiveRoom () {
      this.$router.push('/user/live-enter')
    },
    async showPopup () {
      const { data: res } = await this.$http.get('/index/get-user-info')
      if (res.meta.status === 200) {
        this.profile.username = res.data.username
        this.profile.nick = res.data.nick
        this.profile.avatar[0] = { url: res.data.avatar }
        this.profile.gender = res.data.gender
        this.profile.phone = res.data.phone
        this.profile.birthday = res.data.birthday
        this.profile.location = res.data.location
        this.profile.selfSignature = res.data.selfSignature
        this.profile.allowType = res.data.allowType
        this.show = true
      } else {
        this.$toast.fail(res.meta.msg)
      }
    },
    onConfirm (values) {
      this.profile.location = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.showArea = false
    },
    async afterUpload (file) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.profile.avatar[0] = { url: res.data }
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    },
    async applyUpload (file) {
      this.$toast('上传中...')
      var Fromdata = new FormData()
      Fromdata.append('file', file.file)
      const { data: res } = await this.$http.post('/index/upload', Fromdata)
      if (res.meta.status === 200) {
        this.applyRoom.avatar[0].url = res.data
        this.applyRoom.avatar[0].deletable = true
        this.applyRoom.avatar[0].imageFit = 'contain'
        this.applyRoom.avatar[0].previewSize = 120
        this.$toast.success('上传成功')
        this.$forceUpdate()
      } else {
        setTimeout(() => {
          this.$toast.fail('上传失败')
        }, 1000)
      }
    },
    confirmBirthday (type, val) {
      this.profile.birthday = this.timeFormat(this.pickerDate.currentDate)
      this.birthdayPickerShow = false
    },
    timeFormat (time) { // 时间格式化 2019-09-08
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const day = time.getDate()
      return year + '年' + month + '月' + day + '日'
    }
  }
}
</script>

<style scoped>
.menu-cell{
  text-align: left;
  padding-top: 40px;
  background-color: #FFFFFF;
}
/deep/.van-radio--horizontal{
  padding: 4px;
}
.edit-arvat{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.edit-arvat span{
  color: #6d6d6d;
  font-size: 12px;
  margin-top: 2px;
  display: block;
}
.edit-user-popup{
  padding: 20px;
  box-sizing: border-box;
}
.apply-room-popup{
  padding: 20px;
  box-sizing: border-box;
}
.logout{
  background-color: #FFBB12;
  color: #FFFFFF;
  padding: 10px;
  font-size: 13px;
  margin: 10%;
  border-radius: 4px;
  text-align: center;
}
.user-menu{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #FFFFFF;
  padding: 30px 20px 20px 20px;
}
.user-menu .menu-item{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.user-menu .menu-item img{
  width: 30px;
  height: 30px;
}
.user-menu .menu-item span{
  font-size: 13px;
  margin-top: 4px;
}
.user-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  margin-top: 3vh;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}
.user-info .avart{
  width: 50px;
  height: 50px;
}
.user-info .avart img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-info .nickname{
  flex: 1;
  text-align: left;
  margin-left: 12px;
  font-size: 14px;
}
.user-info .seeting-icon{}
.user-info .seeting-icon .van-icon{
  font-size: 22px;
}
.wave{
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}
.information{
  position: relative;
  text-align: center;
  background: linear-gradient(
    120deg
    , rgba(255,187,18,1) 0%, rgba(255,237,193,1) 100%);
  color: white;
  width: 100%;
  height: 20vh;
}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
.waves {
  height:40px;
  min-height:40px;
  width: 100%;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform:rotate(200deg);
  }
  100%{
    transform: rotate(0);
  }
}

.rotate{
  transition: 0.5s;
  animation: rotate 10s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}
</style>
